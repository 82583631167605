import React from "react"
import Dropdown from "../../components/UI/Dropdown/Dropdown"
import AddressSelect from "./AddressSelect/AddressSelect"
import StoreSelect from "./StoreSelect/StoreSelect"
import { Translation } from "react-i18next"
import { Redirect } from 'react-router'
import { ecommerceService } from "../../services/ecommerce/EcommerceService"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import * as actionCreators from "../../redux/store/actions/index"
import config from "../../config/config"

class CartCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveryModes: [],
            selectedDeliveryMode: "",
            stores: [],
            selectedStore: config.DEFAULT_STORE_ID,
            addressId: null
        }
    }

    componentDidMount() {
        ecommerceService.deliveryModes()
            .then(data => {
                this.setState({
                    deliveryModes: data
                })
            });
    }

    selectDeliveryMode = (item) => {
        this.setState({ selectedDeliveryMode: item.id, addressId: "" });
    }

    selectStore = (item) => {
        this.setState({ selectedStore: item.id });
    }

    selectAddress = (item) => {
        this.setState({ addressId: item.id });
    }

    render() {
        const { deliveryModes, selectedDeliveryMode, selectedStore, addressId } = this.state;
        const { open, cart } = this.props;
        if (cart) return <Redirect to="/" />
        else return (
            <Translation>
                {t =>
                    <div className="bg">
                        <div className="container">
                            <div className="col-xs-12 col-md-6 col-lg-5 offset-lg-7 offset-md-6">
                                <div className="wrapper">
                                    <div className="wrapper-box">
                                        <h1 className="cart-create-title">{t("create.cart.title")}</h1>
                                        <div>
                                            <div className="cart-create-select">
                                                <Dropdown
                                                    items={deliveryModes}
                                                    select={this.selectDeliveryMode}
                                                    label={t("create.cart.service")}
                                                    isDeliveryMode={true}
                                                />
                                                {(selectedDeliveryMode === "AT_HOME" ||
                                                    selectedDeliveryMode === "SHIPPED") &&
                                                    <AddressSelect
                                                        select={this.selectAddress}
                                                        label={t("cartCreate.deliveryAddress")}
                                                    />
                                                }
                                                {/* {(selectedDeliveryMode === "IN_STORE" ||
                                                    selectedDeliveryMode === "DRIVE") && selectedDeliveryMode &&
                                                    <StoreSelect
                                                        deliveryMode={selectedDeliveryMode}
                                                        select={this.selectStore}
                                                        label={t("create.cart.store")}
                                                    />
                                                } */}
                                                <button className="cta"
                                                    disabled={selectedDeliveryMode === "" ||
                                                        ((selectedDeliveryMode === "AT_HOME" || selectedDeliveryMode === "SHIPPED") && !addressId) ||
                                                        ((selectedDeliveryMode === "IN_STORE" || selectedDeliveryMode === "DRIVE") && selectedStore === "")}
                                                    onClick={() => { open(selectedDeliveryMode, selectedStore, addressId) }}>
                                                    <span>{t("create.cart")}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        open: (deliveryMode, storeId, addressId) => dispatch(actionCreators.open(deliveryMode, storeId, addressId)),
    };
}

const mapStateToProps = state => {
    return {
        cart: state.cart.cart,
        storeId: state.user.storeId
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CartCreate));