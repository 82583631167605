import React from "react"
import Form from 'react-bootstrap/Form'
import { Translation } from "react-i18next";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import it from "date-fns/esm/locale/it";
registerLocale("it", it)
setDefaultLocale(it);

export default class SignupDatepicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFocused: false
        }
    }

    onFocus = () => {
        this.setState({
            isFocused: true
        })
    }

    onBlur = () => {
        if (this.props.value === "" || this.props.value === null) {
            this.setState({
                isFocused: false
            })
        }
    }
    render() {
        const cssClass = this.state.isFocused ? "custom-input-placeholder custom-input-placeholder-elevated" : "custom-input-placeholder";
        return (
            <Translation>
                {t =>
                    <Form.Group style={{ marginBottom: "unset" }}>
                        <Form.Label className={cssClass}>{this.props.label}</Form.Label>
                        <DatePicker
                            isClearable
                            autoComplete="off"
                            selected={this.props.value}
                            onChange={this.props.onChange}
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            value={this.props.value}
                            dateFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                            locale="it"
                            showMonthDropdown
                            showYearDropdown
                            fixedHeight
                            scrollableYearDropdown
                            yearDropdownItemNumber={100}
                            popperPlacement="bottom-start"
                            popperClassName="smartbip-datepicker"
                            customInput={<input className="custom-input" />}
                        />
                    </Form.Group>
                }
            </Translation>
        );
    }
}