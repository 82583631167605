import React from "react";
import Counter from "../../../components/UI/Counter/Counter"
import Placeholder from "../../../images/product-placeholder.png"
import translation from "../../../i18n"
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from "react-redux"
import * as actionCreators from "../../../redux/store/actions/index"

import "./cart-item.styles.scss";
import renderPrice from "../../../utils/renderPrice";

class CartItem extends React.Component {
    incrementWeight = (store, cartId, item) => {
        let newWeight = item.weight + item.umStep;
        newWeight = newWeight.toFixed(2);
        newWeight = parseFloat(newWeight);
        store(cartId, item.barcode, newWeight, item.um);
    }

    decrementWeight = (store, cartId, item) => {
        let newWeight = item.weight - item.umStep;
        newWeight = newWeight.toFixed(2);
        newWeight = parseFloat(newWeight);
        if (item.weight > item.umStep) {
            store(cartId, item.barcode, newWeight, item.um);
        }
    }

    renderPromo = (item) => {
        if (item.promotions) {
            if (item.promotions[0] !== null && item.promotions[0] !== undefined) {
                const label = item.promotions[0].valueType === "UNKNOWN" ? "promo.unknown" : "promo";
                return (
                    <p className="cart-product-description__promo">
                        {translation.t(label)}
                    </p>
                );
            } else {
                return null;
            }
        }
    }

    roundWeight = (item) => {
        let { weight, amount } = item
        // es 533 => 540
        weight = weight * 1000
        weight = weight / 10;
        weight = Math.ceil(weight);
        weight = weight * 10;
        weight = weight * amount;
        if (weight >= 1000) {
            return `(${weight / 1000} kg Circa)`
        } else {
            return `(${weight} g Circa)`
        }
    }

    renderPrices = (item) => {
        let result;
        if (item.price !== item.netPrice) {
            result =
                <>
                    <p>
                        <nobr><span className="mr-1 text-strike">
                            {`${renderPrice(item.price.toFixed(2))} \u20AC`}
                        </span>
                        </nobr>
                        <span>
                            {`${renderPrice(item.netPrice.toFixed(2))} \u20AC`}
                        </span>
                    </p>
                    {item.vatConfigurationId && item.attrib3 &&
                        <p>
                            <span className="product-detail-netPrice d-none d-sm-inline">{`${item.attrib3} \u20AC / kg`}</span>
                        </p>
                    }
                </>
        } else {
            result = <div><p>
                <span>{`${renderPrice(item.netPrice.toFixed(2))} \u20AC`}</span>
            </p>
                {item.vatConfigurationId && item.attrib3 &&
                    <p>
                        <span className="product-detail-netPrice d-none d-sm-inline">{`${item.attrib3} \u20AC / kg`}</span>
                    </p>
                }
            </div>
        }
        return result
    }

    render() {
        const { item, cart, store, remove } = this.props;
        const link = `/product/${item.barcode}`;
        return (
            <div className="article">
                <div className="row no-gutters">
                    {/* Only for desktop device */}
                    <div className="article__image d-none d-sm-block">
                        <Link className="link"
                            to={{
                                pathname: link,
                                state: { product: item, isDetail: true }
                            }}>
                            <img src={item.imageUrl ? item.imageUrl : Placeholder} alt=""
                                onError={(e) => { e.target.src = Placeholder }}
                            />
                        </Link>
                    </div>

                    <div className="col pl-0 px-sm-0 pr-sm-4">
                        <div className="container-fluid article__centerer">
                            <div className="row two-line" >

                                {/* Only for mobile device */}

                                <div className="col-2 d-block d-sm-none article__image">
                                    <Link
                                        to={{
                                            pathname: link,
                                            state: { product: item, isDetail: true }
                                        }}>
                                        <img src={item.imageUrl ? item.imageUrl : Placeholder} alt=""
                                            onError={(e) => { e.target.src = Placeholder }}
                                        />
                                    </Link>
                                </div>

                                <div className="col-6 col-sm-4 pr-0">
                                    <div className="article__info">
                                        <Link className="link"
                                            to={{
                                                pathname: link,
                                                state: { product: item, isDetail: true }
                                            }}>
                                            <p className="article__name">{item.description}</p>
                                        </Link>
                                        <span className="article__promo">{this.renderPromo(item)}</span>
                                    </div>
                                </div>
                                <div className="col-2 col-sm-2 text-right px-0 pl-sm-0">
                                    <div className="article__price">
                                        {this.renderPrices(item)}
                                    </div>
                                </div>

                                <div className="col-1 col-sm-1 px-0 text-right text-sm-center d-block d-sm-none">
                                    <div className="article__remove">
                                        <button className="button-clean outline primary-text" onClick={() => remove(cart.id, item.barcode)}>
                                            <FontAwesomeIcon icon="times-circle" size="1x" />
                                        </button>
                                    </div>
                                </div>

                                <div className="col-3 col-sm-1 px-0 pr-sm-auto" />

                                <div className="col-4 col-sm-2 px-0 pr-sm-auto text-center">
                                    {item.um === "PZ"
                                        ?
                                        <Counter
                                            value={item.amount}
                                            um={item.um}
                                            increment={() => {
                                                if (item.maxAmount) {
                                                    if (item.maxAmount >= (item.amount + 1)) {
                                                        store(cart.id, item.barcode, item.amount + 1, item.um)
                                                    }
                                                } else {
                                                    store(cart.id, item.barcode, item.amount + 1, item.um)
                                                }
                                            }}
                                            decrement={() => item.amount > 1 && store(cart.id, item.barcode, item.amount - 1, item.um)}
                                        />
                                        :
                                        <Counter
                                            value={item.weight}
                                            um={item.um}
                                            increment={() => this.incrementWeight(store, cart.id, item)}
                                            decrement={() => this.decrementWeight(store, cart.id, item)}
                                        />
                                    }

                                    <p>{item.vatConfigurationId && item.weight &&
                                        <span className="product-detail-netPrice d-none d-sm-inline">
                                            {this.roundWeight(item)}
                                        </span>}
                                    </p>
                                </div>
                                <div className="col-4 col-sm-2 px-0 text-center text-sm-center">
                                    <div className="article__total">
                                        <span>{`${renderPrice(item.rowNetPrice.toFixed(2))} \u20AC`}</span>
                                    </div>
                                </div>
                                <div className="col-1 col-sm-1 px-0 text-right text-sm-center d-none d-sm-block">
                                    <div className="arcticle__remove">
                                        <button className="button-clean outline primary-text" onClick={() => remove(cart.id, item.barcode)}>
                                            <FontAwesomeIcon icon="times-circle" size="1x" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        cart: state.cart.cart
    };
}

const mapDispatchToProps = dispatch => {
    return {
        store: (cartId, barcode, amount, um) => dispatch(actionCreators.store(cartId, barcode, amount, um)),
        remove: (cartId, barcode) => dispatch(actionCreators.remove(cartId, barcode)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CartItem);