import config from "../../config/config"
import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';

export const smartlistService = {
    addFavorite, getFavorites, deleteFavorite, getSlists, itemsCreate, itemsCreateCustom,
    fetchSlist, itemsDelete, itemsUpdate, createSlist, deleteSlist, renameSlist, copySlist
}

function addFavorite(barcode) {
    return (
        fetch(`${config.baseUrl}/cli/slists/products/favorite/add`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                barcode: barcode,
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function deleteFavorite(barcode) {
    return (
        fetch(`${config.baseUrl}/cli/slists/products/favorite/delete`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                barcode: barcode,
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function getFavorites() {
    return (
        fetch(`${config.baseUrl}/cli/slists/products/favorite/all`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: "include",
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function getSlists() {
    return (
        fetch(`${config.baseUrl}/cli/slists`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: "include",
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function itemsCreate(listId, barcode, amount) {
    return (
        fetch(`${config.baseUrl}/cli/slists/items/create`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                id: listId,
                barcode: barcode,
                amount: amount
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function itemsCreateCustom(listId, amount, description) {
    return (
        fetch(`${config.baseUrl}/cli/slists/items/create`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                id: listId,
                type: "CUSTOM",
                amount: amount,
                description: description
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function fetchSlist(id) {
    return (
        fetch(`${config.baseUrl}/cli/slists/${id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: "include",
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function itemsDelete(listId, itemId) {
    return (
        fetch(`${config.baseUrl}/cli/slists/items/delete`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                id: listId,
                itemId: itemId,
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function itemsUpdate(listId, itemId, amount) {
    return (
        fetch(`${config.baseUrl}/cli/slists/items/update`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                id: listId,
                itemId: itemId,
                amount: amount
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function createSlist(title) {
    return (
        fetch(`${config.baseUrl}/cli/slists/create`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                title: title
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function deleteSlist(id) {
    return (
        fetch(`${config.baseUrl}/cli/slists/delete`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                id: id
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function renameSlist(id, title) {
    return (
        fetch(`${config.baseUrl}/cli/slists/rename`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                id: id,
                title: title
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function copySlist(id, title) {
    return (
        fetch(`${config.baseUrl}/cli/slists/copy`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                id: id,
                title: title
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

