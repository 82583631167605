import React from "react"
import categories from "./Service"
import Slider from "react-slick";
import { withRouter } from 'react-router-dom'
import { ReactComponent as CategoryIcon } from "../../images/icona-slider.svg"

import './category-slider.styles.scss'

class CategorySlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            isLoaded: false,
            dragging: false
        }
    }

    chooseCategory = (category) => {
        if (!this.state.dragging) {
            this.props.history.push({
                pathname: '/category',
                state: { category }
            })
        }
    }

    setDragging = (isDragging) => {
        this.setState({ dragging: isDragging })
    }

    componentDidMount() {
        categories().then(response => {
            this.setState({
                categories: response,
                isLoaded: true
            })
        }).catch((error) => {
            console.log(error);
        })
    }

    render() {
        const { categories, isLoaded } = this.state;
        const settings = {
            infinite: true,
            variableWidth: true,
            slidesToShow: 5,
            slidesToScroll: 3,
            beforeChange: () => this.setDragging(true),
            afterChange: () => this.setDragging(false),
            draggable: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        infinite: true,
                        variableWidth: true,
                        slidesToShow: 4,
                        slidesToScroll: 2,
                        draggable: true,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1,
                        swipeToSlide: true,
                        arrows: false,
                        draggable: true
                    }
                }
            ]
        }
        if (isLoaded) {
            return (
                <div className="category-slider-wrapper">
                    <Slider {...settings}>
                        {categories && categories.map(category => (
                            // onClick={() => this.choseCategory(category)}
                            <div className="category-slider-card" key={category.id} onClick={() => this.chooseCategory(category)}>
                                {/* <div className="category-slider-circle my-auto"></div> */}
                                <CategoryIcon />
                                <div className="category-slider-content">
                                    {category.description}
                                </div>
                            </div>
                        ))
                        }
                    </Slider>
                </div>
            );
        } else {
            return null;
        }
    }
}





function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        />
    );
}

export default withRouter(CategorySlider) 