import React from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import PaymentsMode from "./PaymentsMode/PaymentsMode";
import { Redirect } from 'react-router'
import { Translation } from 'react-i18next';
import renderPrice from "../../../utils/renderPrice";

class Summary extends React.Component {
  render() {
    const { cart, freeze, timeSlotId, enableProductsReplacement,
      enableReplacement, paymentMethod, setPaymentMethod } = this.props;

    if (!timeSlotId) {
      return <Redirect to='/checkout' />;
    }

    return (
      <Translation>
        {t =>
          <div>
            <div className="checkout-data-title">{t("checkout.summary.title")}</div>
            <div className="checkout-summary">
              <div className="container-fluid">
                <Row className="mb-3">
                  <Col className="px-0">
                    {t("subTotal")}
                  </Col>
                  <Col className="px-0 text-right checkout-summary-total-text">
                    {cart && `\u20AC ${renderPrice(cart.netTotal.toFixed(2))}`}
                  </Col>
                </Row>
                {cart && cart.fees && cart.fees.map(fee => (
                  <Row className="mb-3" key={fee.id}>
                    <div className="col-8 px-0">
                      {fee.description}
                    </div>
                    <div className="col-4 px-0 text-right checkout-summary-total-text">
                      {cart && `\u20AC ${renderPrice(fee.value.toFixed(2))}`}
                    </div>
                  </Row>
                ))}
                <Row className="pt-3 mb-3 checkout-summary-total">
                  <Col className="px-0">
                    {t("total")}*
                  </Col>
                  <Col className="px-0 text-right">
                    {cart && cart.totalToPay ? `\u20AC ${renderPrice(cart.totalToPay.toFixed(2))}` : `\u20AC ${renderPrice(cart.netTotal.toFixed(2))}`}
                  </Col>
                </Row>
              </div>
            </div>
            {/* <div className="checkout-subtitle">
              {t("summary.rating")}
            </div> */}

            {/* <hr />

            <div style={{ marginBottom: "unset" }} className="checkout-data-title">
              {t("summary.checkoutTitle")}
            </div>

            <div className="d-flex mt-3">
              <div className="radio-button mr-3">
                <div className="custom-control custom-radio">
                  <input type="radio" className="custom-control-input"
                    id="isEnabledReplacement"
                    name="isEnabledReplacement"
                    value={true}
                    checked={enableProductsReplacement === true}
                    onChange={() => enableReplacement(true)}
                  />
                  <label className="custom-control-label" htmlFor="isEnabledReplacement">
                    Si
                  </label>
                </div>
              </div>
              <div className="radio-button">
                <div className="custom-control custom-radio">
                  <input type="radio" className="custom-control-input"
                    id="isDisableReplacement"
                    name="isDisableReplacement"
                    value={false}
                    checked={enableProductsReplacement === false}
                    onChange={() => enableReplacement(false)}
                  />
                  <label className="custom-control-label" htmlFor="isDisableReplacement">
                    No
                  </label>
                </div>
              </div>
            </div> */}

            {/* <hr />

            <PaymentsMode
              cart={cart}
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
            /> */}

            <div className="text-center">
              <button
                disabled={paymentMethod === null || enableProductsReplacement === null}
                style={{ width: "293px" }}
                className="cta"
                onClick={() => freeze(cart.id)}
              >
                <span>{t("summary.orderConclusion")}</span>
              </button>
            </div>

          </div>
        }
      </Translation>
    )
  }
}

export default Summary


