import React, { useState, useEffect } from "react"
import { useForm } from 'react-hook-form'
import { Translation } from 'react-i18next';
import { authenticationService } from "../../services/basis/AuthenticationService"
import { history } from "../../App"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ValidateInput from "../../components/UI/Input/ValidateInput";
import swal from "../../utils/swal";
import SignupDatepicker from "./SignupDatepicker";
import SignupBanner from "../../images/signup-banner.png"
// import { ReactComponent as FacebookIcon } from "../../images/facebook-cta.svg"
// import Accepts from "../../components/Accepts/Accepts";

import "./signup.styles.scss";

function Signup(props) {

    const { register, handleSubmit, errors, watch, setValue } = useForm({
        mode: 'onBlur',
    });

    const [birthDate, setBirthdate] = useState();

    useEffect(() => {
        register(
            { name: "birthDate" },
            { required: "Campo obbligatorio" },
        )
        setValue("birthDate", birthDate);
    }, [register, birthDate, setValue])

    const handleChange = date => {
        setValue("birthDate", date);
        setBirthdate(date);
        if (date) {
            errors.birthDate = {};
            errors.birthDate.message = "";
        } else {
            errors.birthDate = {};
            errors.birthDate.message = "Campo obbligatorio";
        }
    }

    const onSubmit = data => {
        authenticationService.signup(data)
            .then(response => {
                swal.success("Registrazione effettuata",
                    "A breve riceverai una email per completare il processo di registrazione.");
                history.push("/");
            }).catch((error) => {
                if (error.errorCode === 13) {
                    swal.error("Password non valida");
                } else {
                    swal.error(error.message);
                }
            })
    }

    // console.log(watch())
    // console.log(watch('errors'))

    return (
        <Translation>
            {t =>
                <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off"
                    className={`signup needs-validation ${errors ? "had-validation" : ""}`}>

                    <div className="signup-banner" style={{ backgroundImage: `url(${SignupBanner})` }} alt="">
                        <div className="text text-center">
                            <span>
                               {t("signup.registerToAccessServices")}</span>
                        </div>
                    </div>
                    <div className="signup-content">
                        {/* <div className="signup-facebook">
                            <span className="title">Accedi tramite Facebook</span>
                            <div>
                                <button type="button" className="facebook-cta">
                                    <FacebookIcon />
                                    <span>Accedi con Facebook</span>
                                </button>
                            </div>
                        </div>
                        <div className="separator"><span>Oppure</span></div> */}
                        <div className="signup-card">
                            <span className="card-title">{t("signup.personalData")}</span>
                            <hr className="hr" />
                            <Row>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="firstName"
                                        label="Nome*"
                                        type="text"
                                        watch={watch("firstName")}
                                        register={register({
                                            required: "Campo obbligatorio",
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.firstName && errors.firstName.message}
                                    </div>
                                </Col>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="lastName"
                                        label="Cognome*"
                                        type="text"
                                        watch={watch("lastName")}
                                        register={register({
                                            required: "Campo obbligatorio",
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.lastName && errors.lastName.message}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} className="form-group">
                                    <SignupDatepicker
                                        label="Data di nascita*"
                                        onChange={handleChange}
                                        value={birthDate}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.birthDate && errors.birthDate.message}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="username"
                                        label="Indirizzo email*"
                                        type="email"
                                        watch={watch("username")}
                                        register={register({
                                            required: "Campo obbligatorio",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: "Indirizzo email non valido"
                                            }
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.username && errors.username.message}
                                    </div>
                                </Col>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="mobilePhone"
                                        label="Telefono*"
                                        type="tel"
                                        watch={watch("mobilePhone")}
                                        register={register({
                                            required: "Campo obbligatorio",
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.mobilePhone && errors.mobilePhone.message}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="password"
                                        label="password"
                                        type="password"
                                        watch={watch("password")}
                                        register={register({
                                            required: "Campo obbligatorio",
                                            minLength: { value: 8, message: "La password deve essere lunga almeno 8 caratteri" }
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.password && errors.password.message}
                                    </div>
                                </Col>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="passwordConfirm"
                                        label="passwordConfirm"
                                        type="password"
                                        watch={watch("passwordConfirm")}
                                        register={register({
                                            required: "Campo obbligatorio",
                                            validate: {
                                                passwordMatch: value => value === watch('password')
                                            }
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.passwordConfirm && errors.passwordConfirm.message}
                                        {errors.passwordConfirm && errors.passwordConfirm.type === "passwordMatch" && (
                                            <span>{t("signup.passwordsDoesNotMatch")}</span>
                                        )}
                                    </div>
                                </Col>
                                {/* <Row className="container-fluid">
                                    <Col md={12} className="form-group">
                                        <small id="passwordHelp" className="form-text text-muted">
                                            La password deve essere lunga almeno 8 caratteri e contenere
                                            almeno tre tra: lettere minuscole, maiuscole, numeri e simboli
                                    </small>
                                    </Col>
                                </Row> */}
                                {/* <Row className="signup-row">
                                    <Col md={6} className="form-group">
                                        <ValidateInput
                                            name="profileCard"
                                            label="profileCard"
                                            type="number"
                                            watch={watch("profileCard")}
                                            register={register}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.code && errors.code.message}
                                        </div>
                                    </Col>
                                </Row> */}
                            </Row>
                        </div>

                        <div style={{ marginTop: " 21px" }} className="signup-card">
                            <span className="card-title">{t("signup.conditionsOfServiceAndPrivacy")}</span>
                            <hr className="hr" />
                            <div className="custom-control custom-checkbox">
                                <input className="custom-control-input" type="checkbox" id="accept1" name="accept1"
                                    ref={register({
                                        required: "Campo obbligatorio"
                                    })}
                                />
                                <label className="custom-control-label" htmlFor="accept1">
                                    {t("signup.acceptTermsAndConditions")}
                                </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input className="custom-control-input" type="checkbox" id="accept2" name="accept2"
                                    ref={register({
                                        required: "Campo obbligatorio"
                                    })}
                                />
                                <label className="custom-control-label" htmlFor="accept2">
                                    {t("signup.acceptPrivacyPolicy")}
                                </label>
                            </div>
                        </div>

                        <div className="signup-actions text-center">
                            <button className="cta" type="submit">
                                <span>{t("signup.register")}</span>
                            </button>
                        </div>
                    </div>

                </form>
            }
        </Translation >
    );
}

export default Signup;