import React from "react"
import { Link, withRouter } from "react-router-dom";

import "./header-item.styles.scss";
import renderPrice from "../../../../utils/renderPrice";

const HeaderCartItem = (props) => {
    return (
        <Link className="link" to={props.path} onClick={props.closeAll}>
            <div className={`header-item header-item-container 
                ${(props.history.location.pathname === "/cart" ||
                    props.history.location.pathname === "/cart/create" ||
                    props.history.location.pathname === "/carts") ? "header-item-actived" : ""}`}
                onClick={props.onClick}
            >
                {props.items > 0 &&
                    <div className="header-item-counts">
                        <span>{props.items}</span>
                    </div>
                }
                {props.children}
                {props.total && props.items > 0 &&
                    <div className="header-item-total">
                        <span>{renderPrice(props.total)}</span>
                    </div>
                }
            </div>
        </Link>
    );
}

export default withRouter(HeaderCartItem);