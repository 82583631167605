import React from "react"
import { Translation } from "react-i18next";
import { connect } from "react-redux"
import * as actionCreators from "../../redux/store/actions/index"
import CurrentSmartlist from "./CurrentSmartlist/CurrentSmartlist";
import SmartlistHeader from "./SmartlistHeader/SmartlistHeader";
import Popup from "../../components/UI/Popup/Popup";
import PopupInput from "../../components/UI/Popup/PopupInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import i18n from "../../i18n"

class Smartlist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectTitle: "",
            copyTitle: "",
            createTitle: ""
        }
    }

    componentDidMount() {
        this.setState({
            selectTitle: this.props.currentSlist && this.props.currentSlist.title,
            copyTitle: this.props.currentSlist && `Copia di ${this.props.currentSlist.title}`
        })
        this.props.getSlists();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentSlist !== this.props.currentSlist) {
            this.setState({
                selectTitle: this.props.currentSlist && this.props.currentSlist.title,
                copyTitle: this.props.currentSlist && `Copia di ${this.props.currentSlist.title}`
            })
        }
    }

    onChange = (e) => this.setState({ [e.target.name]: e.target.value });

    renderCreate = () => {
        return (
            <Translation>
                {t =>
            <PopupInput
                action={() => this.props.createSlist(this.state.createTitle)}
                title={i18n.t("smartlist.create")}
                button={i18n.t("create")}
                onChange={this.onChange}
                name="createTitle"
                trigger={
                    <span>
                        <button className="d-none d-sm-inline smartlist-header-button outline">
                            <span className="pr-0"><FontAwesomeIcon icon="plus" /></span>
                            <span>{t("smartList.createNew")}</span>
                        </button>
                        <button className="d-block d-sm-none smartlist-header-button outline">
                            <FontAwesomeIcon icon="plus" />
                        </button>
                    </span>
                }
            />
                }
            </Translation>
        );
    }

    renderAbort = (id) => {
        return (
            <Translation>
                {t =>
            <Popup
                action={() => this.props.deleteSlist(id)}
                title={i18n.t("smartlist.delete")}
                button={i18n.t("delete")}
                content={i18n.t("smartlist.delete.question")}
                trigger={
                    <span>
                        <button className="d-none d-lg-inline smartlist-header-button smartlist-header-button--secondary outline">
                            <div className="text">
                                <span className="pr-0"><FontAwesomeIcon icon="trash-alt" /></span>
                                <span>{t("delete")}</span>
                            </div>
                        </button>
                        <button className="d-lg-none ml-3 button-clean outline primary-text">
                            <FontAwesomeIcon icon="trash-alt" style={{ width: "20px", height: "20px" }} />
                        </button>
                    </span>
                }
            />
                }
            </Translation>
        );
    }

    renderCopy = (id) => {
        return (
            <Translation>
                {t =>
            <PopupInput
                action={() => this.props.copySlist(id, this.state.copyTitle)}
                title={i18n.t("smartlist.copy")}
                button={i18n.t("copy")}
                onChange={this.onChange}
                value={this.state.copyTitle}
                name={"copyTitle"}
                trigger={
                    <span>
                        <button className="d-none d-lg-inline smartlist-header-button smartlist-header-button--secondary outline">
                            <div className="text">
                                <span className="pr-0"><FontAwesomeIcon icon="copy" /></span>
                                <span>{t("copy")}</span>
                            </div>
                        </button>
                        <button className="d-lg-none ml-3 button-clean outline primary-text">
                            <FontAwesomeIcon icon="copy" style={{ width: "20px", height: "20px" }} />
                        </button>
                    </span>
                }
            />    }
            </Translation>
        );
    }

    renderRename = (id) => {
        return (
            <Translation>
                {t =>
            <PopupInput
                action={() => this.props.renameSlist(id, this.state.selectTitle)}
                title={i18n.t("smartlist.rename")}
                button={i18n.t("rename")}
                onChange={this.onChange}
                value={this.state.selectTitle}
                name={"selectTitle"}
                trigger={
                    <span>
                        <button className="d-none d-lg-inline smartlist-header-button smartlist-header-button--secondary outline">
                            <div className="text">
                                <span className="pr-0"><FontAwesomeIcon icon="edit" /></span>
                                <span className="d-none d-sm-block">{t("rename")}</span>
                            </div>
                        </button>
                        <button className="d-lg-none button-clean outline primary-text">
                            <FontAwesomeIcon icon="edit" style={{ width: "20px", height: "20px" }} />
                        </button>
                    </span>
                }
            />
                }
            </Translation>
        );
    }

    render() {
        const { slists, currentSlist, createSlist, fetchSlist,
            deleteSlist, renameSlist, copySlist, itemDelete, itemUpdate } = this.props;
        return (
            <Translation>
                {t =>
                    <>
                        <SmartlistHeader
                            slist={currentSlist}
                            slists={slists}
                            fetchSlist={fetchSlist}
                            deleteSlist={deleteSlist}
                            renameSlist={renameSlist}
                            copySlist={copySlist}
                            createSlist={createSlist}
                            renderAbort={this.renderAbort}
                            renderCopy={this.renderCopy}
                            renderRename={this.renderRename}
                            renderCreate={this.renderCreate}
                        />
                        <CurrentSmartlist
                            slist={currentSlist}
                            itemDelete={itemDelete}
                            itemUpdate={itemUpdate}
                            renderAbort={this.renderAbort}
                            renderCopy={this.renderCopy}
                            renderRename={this.renderRename}
                        />
                    </>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        slists: state.slists.slists,
        currentSlist: state.slists.currentSlist,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        getSlists: () => dispatch(actionCreators.getSlists()),
        fetchSlist: (id) => dispatch(actionCreators.fetchSlist(id)),
        createSlist: (title) => dispatch(actionCreators.createSlist(title)),
        deleteSlist: (id) => dispatch(actionCreators.deleteSlist(id)),
        renameSlist: (id, title) => dispatch(actionCreators.renameSlist(id, title)),
        copySlist: (id, title) => dispatch(actionCreators.copySlist(id, title)),
        itemDelete: (listId, itemId) => dispatch(actionCreators.itemDelete(listId, itemId)),
        itemUpdate: (listId, itemId, amount) => dispatch(actionCreators.itemUpdate(listId, itemId, amount))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Smartlist);