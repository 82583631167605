import React from "react"
import enot from "../images/departments/enot.png"
import fipi from "../images/departments/fipi.png"
import gast from "../images/departments/gast.png"
import orto from "../images/departments/orto.png"
import pane from "../images/departments/pane.png"
import past from "../images/departments/past.png"
import pesc from "../images/departments/pesc.png"

import arbi from "../images/services/arbi.png"
import bain from "../images/services/bain.png"
import banc from "../images/services/banc.png"
import bar_ from "../images/services/bar_.png"
import bupa from "../images/services/bupa.png"
import caau from "../images/services/caau.png"
import edic from "../images/services/edic.png"
import fide from "../images/services/fide.png"
import gica from "../images/services/gica.png"
import locl from "../images/services/locl.png"
import paco from "../images/services/paco.png"
import pael from "../images/services/pael.png"
import pafa from "../images/services/pafa.png"
import parc from "../images/services/parc.png"
import rite from "../images/services/rite.png"
import sesc from "../images/services/sesc.png"
import taba from "../images/services/taba.png"
import wifi from "../images/services/wifi.png"

export const storeIcons = { renderDepartments, renderServices }

function renderDepartments(department) {
    var icon = null;
    switch (department) {
        case "enot": icon = <img src={enot} alt="" />
            break;
        case "fipi": icon = <img src={fipi} alt="" />
            break;
        case "gast": icon = <img src={gast} alt="" />
            break;
        case "orto": icon = <img src={orto} alt="" />
            break;
        case "pane": icon = <img src={pane} alt="" />
            break;
        case "past": icon = <img src={past} alt="" />
            break;
        case "pesc": icon = <img src={pesc} alt="" />
            break;

        default: return icon;
    }

    return icon;
}

function renderServices(service) {
    var icon = null;
    switch (service) {
        case "arbi": icon = <img src={arbi} alt="" />
            break;
        case "bain": icon = <img src={bain} alt="" />
            break;
        case "banc": icon = <img src={banc} alt="" />
            break;
        case "bar_": icon = <img src={bar_} alt="" />
            break;
        case "bupa": icon = <img src={bupa} alt="" />
            break;
        case "caau": icon = <img src={caau} alt="" />
            break;
        case "edic": icon = <img src={edic} alt="" />
            break;
        case "fide": icon = <img src={fide} alt="" />
            break;
        case "gica": icon = <img src={gica} alt="" />
            break;
        case "locl": icon = <img src={locl} alt="" />
            break;
        case "paco": icon = <img src={paco} alt="" />
            break;
        case "pael": icon = <img src={pael} alt="" />
            break;
        case "pafa": icon = <img src={pafa} alt="" />
            break;
        case "parc": icon = <img src={parc} alt="" />
            break;
        case "rite": icon = <img src={rite} alt="" />
            break;
        case "sesc": icon = <img src={sesc} alt="" />
            break;
        case "taba": icon = <img src={taba} alt="" />
            break;
        case "wifi": icon = <img src={wifi} alt="" />
            break;

        default: return icon;
    }

    return icon;
}

