import React from "react"
import ValidateInput from "../../../components/UI/Input/ValidateInput";
// import Accepts from "../../../components/Accepts/Accepts";
import { Row, Col } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Translation } from 'react-i18next';
import { connect } from "react-redux"
import * as actionCreators from "../../../redux/store/actions/index"
import privacy from "./privacyDisclaimer";

import "./profile.styles.scss"

function Profile(props) {

    const { register, handleSubmit, errors, watch } = useForm({
        mode: 'onBlur',
    });

    const onSubmit = data => {
        props.updateUser(data);
    }

    // console.log(watch())
    // console.log(watch('errors'))

    return (
        <Translation>
            {t =>
                <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off" className={`needs-validation ${errors ? "had-validation" : ""}`}>
                    <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />
                    <div className="profile">
                        <p className="profile-title">{t('signup.title')}</p>
                        <div>
                            <Row className="signup-row">
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="firstName"
                                        label="firstName"
                                        type="text"
                                        value={props.user && props.user.firstName}
                                        watch={watch("firstName")}
                                        register={register({
                                            required: "Campo obbligatorio"
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.firstName && errors.firstName.message}
                                    </div>
                                </Col>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="lastName"
                                        label="lastName"
                                        type="text"
                                        value={props.user && props.user.lastName}
                                        watch={watch("lastName")}
                                        register={register({
                                            required: "Campo obbligatorio"
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.lastName && errors.lastName.message}
                                    </div>
                                </Col>
                            </Row>
                            <Row className="signup-row">
                                {/* <Col md={6} className="form-group">
                                        <ValidateInput
                                            name="birthDate"
                                            label="birthDate"
                                            type="date"
                                            value={props.user && props.user.birthDate &&
                                                format(parseISO(props.user.birthDate), "yyyy-MM-dd")}
                                            watch={watch("birthDate")}
                                            register={register({
                                                required: "Campo obbligatorio",
                                                pattern: {
                                                    value: /^\d{4}-\d{2}-\d{2}$/i,
                                                    message: "Data non valida"
                                                }
                                            })}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.birthDate && errors.birthDate.message}
                                        </div>
                                    </Col> */}
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="ssnCode"
                                        label="ssnCode"
                                        type="text"
                                        value={props.user && props.user.ssnCode}
                                        watch={watch("ssnCode")}
                                        register={register({
                                            required: "Campo obbligatorio",
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.ssnCode && errors.ssnCode.message}
                                    </div>
                                </Col>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="mobilePhone"
                                        label="mobilePhone"
                                        type="text"
                                        value={props.user && props.user.mobilePhone}
                                        watch={watch("mobilePhone")}
                                        register={register({
                                            required: "Campo obbligatorio",
                                            pattern: {
                                                value: /^\d{10}$/i,
                                                message: "Numero di cellulare non valido" // todo i18n
                                            }
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.mobilePhone && errors.mobilePhone.message}
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <p className="profile-subtitle">{t('signup.card')}</p>

                        <div>
                            <Row className="signup-row">
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="profileCard"
                                        label="profileCard"
                                        type="number"
                                        value={props.user && props.user.cards && props.user.cards[0] && props.user.cards[0].code}
                                        watch={watch("profileCard")}
                                        register={register({
                                            pattern: {
                                                value: /^0248[0-9]{9}$/,
                                                message: "Carta non valida" // todo i18n
                                            }
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.profileCard && errors.profileCard.message}
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <span style={{ marginTop: "40px" }} className="profile-subtitle">{t('signup.privacy')}</span>

                        <textarea
                            rows="10"
                            cols="69"
                            // defaultValue={privacy}
                            defaultValue="Privacy policy"
                            readOnly
                        />

                        <div className="custom-control custom-checkbox">
                            <input className="custom-control-input" type="checkbox" id="accept1" name="accept1"
                                ref={register({
                                    required: "Campo obbligatorio"
                                })}
                            />
                            <label className="custom-control-label" htmlFor="accept1">
                                {t("profile.acceptTermsAndConditions")}
                                </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input className="custom-control-input" type="checkbox" id="accept2" name="accept2"
                                ref={register({
                                    required: "Campo obbligatorio"
                                })}
                            />
                            <label className="custom-control-label" htmlFor="accept2">
                                {t("profile.acceptPrivacyPolicy")}
                                </label>
                        </div>

                        <div className="profile-save">
                            <button className="cta" type="submit">
                                <span>{t("save")}</span>
                            </button>
                        </div>
                    </div>


                </form>
            }
        </Translation >
    );
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateUser: (data) => dispatch(actionCreators.updateUser(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);