import React from "react";
import Row from "react-bootstrap/Row";
import CardProduct from "../../../components/Products/CardProduct/CardProduct";
import { Translation } from "react-i18next";
import { ecommerceService } from "../../../services/ecommerce/EcommerceService"
import { connect } from "react-redux"
import config from "../../../config/config";

class FeaturedProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            products: []
        };
    }

    componentDidMount() {
        const storeId = this.props.cart ? this.props.cart.storeId : config.DEFAULT_STORE_ID;
        ecommerceService.featuredProducts(storeId).then(response => {
            this.setState({
                isLoaded: true,
                products: response.data
            })
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const storeId = this.props.cart ? this.props.cart.storeId : config.DEFAULT_STORE_ID;
        if (nextProps && nextProps.cart) {
            if (storeId !== nextProps.cart.storeId) {
                ecommerceService.featuredProducts(nextProps.cart.storeId).then(response => {
                    this.setState({
                        isLoaded: true,
                        products: response.data
                    })
                });
            }
        } else {
            ecommerceService.featuredProducts(config.DEFAULT_STORE_ID).then(response => {
                this.setState({
                    isLoaded: true,
                    products: response.data
                })
            });
        }
    }

    render() {
        const { products, isLoaded } = this.state;
        if (isLoaded) {
            return (
                <Translation>
                    {t =>
                        <div className="search-product-view">
                            <Row className="no-gutters justify-content-center">
                                {products && products.map((item) => (
                                    <CardProduct key={item.id} item={item} />
                                ))}
                            </Row>
                        </div>
                    }
                </Translation>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = state => {
    return {
        cart: state.cart.cart,
    };
}

export default connect(mapStateToProps)(FeaturedProducts);
