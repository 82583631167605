import React from "react"

import "./header-item.styles.scss";

const HeaderItem = (props) => {
    return (
        <div className={`header-item ${props.actived ? "header-item-actived" : ""}`}
            onClick={props.onClick}
        >
            {props.children}
        </div>
    );
}

export default HeaderItem;