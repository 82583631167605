import React from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Translation } from 'react-i18next';
import SmartlistItem from "./SmartlistItem/SmartlistItem";

export default class CurrentSmartlist extends React.Component {
    render() {
        const { slist, itemUpdate, itemDelete, renderRename, renderAbort, renderCopy } = this.props;
        return (
            <Translation>
                {t =>
                    <div className="favorite-container w-100">
                        <div className="view-container">
                            <div className="row">
                                <div className="col-7 col-md-5 col-sm-6">
                                    <span className="title mb-4">{slist && slist.title}</span>
                                </div>
                                <div className="col-5 d-block d-lg-none text-right">
                                    {slist && renderRename(slist.id)}
                                    {slist && renderCopy(slist.id)}
                                    {slist && renderAbort(slist.id)}
                                </div>
                            </div>
                            <div className="d-none d-sm-block">
                                <Row className="cart-heading mb-4">
                                    <Col sm={1}>
                                        <span>{t("products")}</span>
                                    </Col>
                                    <Col sm={5}>
                                        <span className="cart-product-description">
                                            {t("description")}
                                        </span>
                                    </Col>
                                    <Col sm={2}>
                                        <span>{t("amount")}</span>
                                    </Col>
                                    <Col sm={2}>
                                        <span>{t("delete")}</span>
                                    </Col>
                                </Row>
                            </div>
                            {slist && slist.items && slist.items.map(item => (
                                <SmartlistItem
                                    key={item.id}
                                    item={item}
                                    currentSlist={slist}
                                    itemUpdate={itemUpdate}
                                    itemDelete={itemDelete}
                                />
                            ))}
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}


