import config from "../../config/config";
import { handleResponse } from "../../utils/handleResponse";
import { checkResponse } from "../../utils/checkResponse";

export const productsService = { info, fetchByBarcode }

function info(barcode) {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/products/barcode/${barcode}/info`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
            credentials: "include"
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function fetchByBarcode(barcode, storeId) {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/products/barcode/${barcode}?store=${storeId}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}