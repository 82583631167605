import React from "react";
import Carousel from 'react-bootstrap/Carousel'
import banners from "./Service"
import Placeholder from "../../../images/banner-placeholder.png"
export default class HomeCarousel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            banners: []
        };
    }

    componentDidMount() {
        banners().then(response => {
            this.setState({
                isLoaded: true,
                banners: response.data
            })
        });
    }

    render() {
        const { isLoaded, banners } = this.state;
        return (isLoaded ?
            <Carousel indicators={banners.length > 1} controls={banners.length > 1} className="Carousel home-carousel">
                {banners.map((item, index) => (
                    <Carousel.Item key={item.id}>
                        <div style={{backgroundImage: `url(${item.image})`}} alt=""></div>
                        {/* <div style={{ backgroundImage: `url(${Placeholder})` }} alt=""></div> */}
                    </Carousel.Item>
                ))}
            </Carousel>

            : null
        );
    }

}