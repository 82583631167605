import React from "react"
import { Translation } from "react-i18next";

export default class ProductInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDescription: true,
            showIngredients: true,
            showAllergy: true,
        }
    }

    renderDescription = () => {
        this.setState({
            showDescription: !this.state.showDescription,
            showIngredients: false,
            showAllergy: false
        })
    }

    renderIngredients = () => {
        this.setState({
            showDescription: false,
            showIngredients: !this.state.showIngredients,
            showAllergy: false
        })
    }

    renderAllergy = () => {
        this.setState({
            showDescription: false,
            showIngredients: false,
            showAllergy: !this.state.showAllergy
        })
    }

    render() {
        const { info } = this.props;
        if (info) {
            return (
                <Translation>
                    {t =>
                        <>
                            <div className="g-padding">
                                {info.description &&
                                    <div className="mb-4">
                                        <div className="product-info-title"
                                        // onClick={this.renderDescription}
                                        >
                                            {t("description")}
                                        </div>
                                        {this.state.showDescription &&
                                            <div className="mb-1">
                                                <div className="product-info">
                                                    <h4>{t("product-name")}:</h4>
                                                    <p>{info.description}</p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                                {info.ingredients &&
                                    <div className="mb-4">
                                        <div className="product-info-title"
                                        // onClick={this.renderIngredients}
                                        >
                                            {t("ingredients")}
                                        </div>
                                        {this.state.showIngredients &&
                                            <div className="mb-1">
                                                <div className="product-info">
                                                    <h4>{info.ingredients}</h4>
                                                    <p></p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                                {info.allergyAdvice &&
                                    <div className="mb-4">
                                        <div className="product-info-title"
                                        // onClick={this.renderAllergy}
                                        >
                                            {t("allergyAdvice")}
                                        </div>
                                        {this.state.showAllergy &&
                                            <>
                                                <div className="mb-1">
                                                    <div className="product-info">
                                                        <h4>{t("contains")}:</h4>
                                                        <p>{info.allergyAdvice}</p>
                                                    </div>
                                                </div>
                                                {info.allergyOther &&
                                                    <div className="mb-1">
                                                        <div className="product-info">
                                                            <h4>{t("may-contains")}:</h4>
                                                            <p>{info.allergyOther}</p>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                }
                                {info.nutrition &&
                                    <div className="mb-4">
                                        <div className="product-info-title">
                                            {t("nutrition")}
                                        </div>
                                        <div className="mb-1">
                                            <div className="product-info">
                                                <h4>{info.nutrition}</h4>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {info.storage &&
                                    <div className="mb-4">
                                        <div className="product-info-title">
                                            {t("storage")}
                                        </div>
                                        <div className="mb-1">
                                            <div className="product-info">
                                                <h4>{info.storage}</h4>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {info.recycling &&
                                    <div className="mb-4">
                                        <div className="product-info-title">
                                            {t("recycling")}
                                        </div>
                                        <div className="mb-1">
                                            <div className="product-info">
                                                <h4>{info.recycling}</h4>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </>
                    }
                </Translation>
            );
        } else {
            return null;
        }
    }
}