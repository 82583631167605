import React from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Placeholder from "../../images/product-placeholder.png";
import CounterInput from "../../components/UI/Counter/CounterInput";
import ProductInfo from "./ProductInfo/ProductInfo";
import GoBackButton from "../../components/UI/Buttons/GoBackButton";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import { productsService } from "./Service";
import * as actionCreators from "../../redux/store/actions/index";
import config from "../../config/config";
import { withRouter } from 'react-router-dom';
import { format, parseISO } from "date-fns";
import renderPrice from "../../utils/renderPrice";


class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: null,
            umStep: null,
            info: [],
            isFavorite: false,
            available: true,
            product: null,
            productImages: null,
            variants: null,
            selectedVariants: [],
            isDetail: false
        }
    }

    componentDidMount() {
        const barcode = this.props.match.params.barcode;
        const isDetail = this.props.location.state ? this.props.location.state.isDetail : null;
        const detailProduct = this.props.location.state ? this.props.location.state.product : null;
        const storeId = config.DEFAULT_STORE_ID;
        productsService.fetchByBarcode(barcode, storeId).then(response => {
            const product = response.data;
            let initialAmount = null;
            let selectedVariants = [];
            // check if is a cart item 
            if (detailProduct) {
                console.log(detailProduct);
                if (isDetail) {
                    // TODO usare il product che arriva dal location state
                    if (detailProduct.um === "PZ") {
                        initialAmount = detailProduct.amount;
                    } else {
                        initialAmount = detailProduct.weight;
                    }
                    // set variants
                    console.log(detailProduct.selectedCartItemVariants);
                    selectedVariants = detailProduct.selectedCartItemVariants;
                } else {
                    initialAmount = detailProduct.umStep;
                }
            } else {
                initialAmount = product.umStep;
            }
            // check if is a favorite item 
            let isFavorite = false;
            if (this.props.favorites) {
                this.props.favorites.forEach(item => {
                    if (this.props.match.params.barcode === item.barcode) {
                        isFavorite = true;
                    }
                });
            }

            this.setState({
                product: product,
                amount: initialAmount,
                umStep: product.umStep,
                isFavorite: isFavorite,
                variants: product.cartItemVariants,
                available: isDetail ? true : product.available,
                selectedVariants: selectedVariants,
                isDetail: isDetail,
                productImages: product && product.images
                    ?
                    product.images.map(item => {
                        return { original: item }
                    })
                    : null
            })


        }).catch((error) => {
            console.log(error);
        })
        productsService.info(barcode).then(response => {
            if (response.data) {
                this.setState({
                    info: response.data,
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    renderPromo = (item) => {
        if (item.promotions) {
            if (item.promotions[0] !== null && item.promotions[0] !== undefined) {
                // const label = item.promotions[0].valueType === "UNKNOWN" ? "promo.unknown" : "promo";
                return (
                    <Translation>
                        {t =>
                            <div className="product-detail-promotion">
                                {t("product.promotionDetails")}{format(parseISO(item.promotions[0].validTo), "dd/MM")}
                            </div>
                        }
                    </Translation>
                );
            } else {
                return null;
            }
        }
    }

    incrementAmount = () => {
        let newAmount = this.state.amount + this.state.umStep;
        newAmount = newAmount.toFixed(2);
        newAmount = parseFloat(newAmount);
        if (this.state.product && this.state.product.maxAmount) {
            if (newAmount <= this.state.product.maxAmount) {
                this.setState({ amount: newAmount })
            }
        } else {
            this.setState({ amount: newAmount })
        }
    }

    decrementAmount = () => {
        let newAmount = this.state.amount - this.state.umStep;
        newAmount = newAmount.toFixed(2);
        newAmount = parseFloat(newAmount);
        if (this.state.amount > this.state.umStep) {
            this.setState({ amount: newAmount })
        }
    }

    renderPrices = (item) => {
        let result;
        if (item.price !== item.netPrice) {
            result =
                <>
                    <span className="mr-1 text-strike product-price">
                        {`${renderPrice((item.price * this.state.amount).toFixed(2))} \u20AC`}
                    </span>
                    <span className="primary-color product-price ml-4">
                        {`${renderPrice((item.netPrice * this.state.amount).toFixed(2))} \u20AC`}
                    </span>
                </>
        } else {
            result = <span
                className="product-price">{`${renderPrice((item.netPrice * this.state.amount).toFixed(2))} \u20AC`}</span>
        }
        return result
    }

    setFavorite = () => {
        const barcode = this.props.match.params.barcode;
        if (this.state.isFavorite) {
            this.props.deleteFavorite(barcode);
        } else {
            this.props.addFavorite(barcode);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.favorites.length - this.props.favorites.length === 1) {
            this.setState({
                isFavorite: true
            })
        }
        if (this.props.favorites.length - nextProps.favorites.length === 1) {
            this.setState({
                isFavorite: false
            })
        }
    }

    goPrevPath = () => {
        if (this.props.location.state) {
            const { from, product, category, mainCategory, categoryTitle, subCategory, activeKey } = this.props.location.state;
            if (from) this.props.history.push({
                pathname: `/category`,
                state: {
                    activeKey,
                    categoryId: product.categoryId,
                    category,
                    mainCategory,
                    categoryTitle,
                    subCategory,
                    from: this.props.location.pathname
                }
            })
            else this.props.history.goBack()
        } else {
            this.props.history.goBack();
        }
    }

    handleChange = (event) => {
        let option = JSON.parse(event.target.value);
        const { isDetail, selectedVariants } = this.state;
        const newVariants = [];
        if (isDetail) {
            selectedVariants && selectedVariants.forEach(item => {
                if (item.variantId === option.variantId) {
                    newVariants.push(option)
                } else {
                    newVariants.push(item);
                }
            })
            this.setState({ selectedVariants: newVariants });
        } else {
            this.setState({ selectedVariants: [...selectedVariants, option] })
        }
    };

    renderSelectedVariants = (variant) => {
        let result = variant.variantValue;
        this.state.selectedVariants && this.state.selectedVariants.forEach(selectedVariant => {
            if (variant.variantId === selectedVariant.variantId) {
                result = selectedVariant.variantOptions[0].optionValue;
            }
        })
        return result;
    }

    checkVariants = () => {
        if (this.state.variants) {
            let variantsNumber = this.state.variants.length;
            let selectedVariantsNumber = 0;
            this.state.variants.forEach(variant => {
                this.state.selectedVariants && this.state.selectedVariants.forEach(selectedVariant => {
                    if (variant.variantId === selectedVariant.variantId) {
                        selectedVariantsNumber++;
                    }
                })
            });
            return (selectedVariantsNumber === variantsNumber ? false : true);
        } else return false;
    }

    render() {
        const { product, info, variants, selectedVariants } = this.state;
        const { user, cart, store, setProductToAdd, showLogin } = this.props;
        if (product) {
            return (
                <Translation>
                    {t =>
                        <div className="text-left container-fluid mobile-view-search">
                            <Row>
                                <Col className="p-0" sm={12} md={12} lg={7} style={{ backgroundColor: "#ffffff" }}>
                                    <div>
                                        <GoBackButton
                                            goBack={() => this.goPrevPath()}>
                                            {t("goBack")}
                                        </GoBackButton>
                                        <div style={{ marginBottom: "15px", paddingTop: "unset" }}
                                            className="view-container">
                                            <div className="product-detail">
                                                <img src={product.imageUrl ? product.imageUrl : Placeholder} alt=""
                                                    onError={(e) => {
                                                        e.target.src = Placeholder
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="p-0" style={{ backgroundColor: "#fbfbfb" }} sm={12} md={12} lg={5}>
                                    <div className="product-container">
                                        <div className="product-detail-ref">C. EAN: {product.barcode}</div>
                                        <h1 className="product-detail-name cart-product-description">{product.description}</h1>
                                        {product.pricePerUm && product.umPerUm &&
                                            <h4 className="product-detail-netPrice">
                                                {`${renderPrice(product.pricePerUm.toFixed(2))} \u20AC / ${product.umPerUm} `}
                                            </h4>
                                        }
                                        {this.renderPromo(product)}
                                        {product.price &&
                                            <div style={{ marginBottom: "25px" }}>
                                                <div className="product-detail-heading">{t("price")}</div>
                                                {this.renderPrices(product)}
                                            </div>
                                        }
                                        <CounterInput
                                            value={this.state.amount}
                                            um={product.purchaseUm}
                                            increment={this.incrementAmount}
                                            decrement={this.decrementAmount}
                                        />

                                        <div className="product-variants">
                                            {variants && variants.map(variant => (
                                                <div>
                                                    <select key={variant.variantId}
                                                        defaultValue={this.renderSelectedVariants(variant)}
                                                        onChange={this.handleChange}
                                                    >
                                                        <option key={variant.variantValue} hidden>
                                                            {this.renderSelectedVariants(variant)}
                                                        </option>
                                                        {variant && variant.variantOptions.map(option => {
                                                            let selectedOption = {};
                                                            selectedOption.variantId = variant.variantId;
                                                            selectedOption.variantValue = variant.variantValue;
                                                            selectedOption.variantOptions = [{
                                                                optionId: option.optionId,
                                                                optionValue: option.optionValue
                                                            }]
                                                            return (
                                                                <option key={option.optionId} value={JSON.stringify(selectedOption)}>
                                                                    {option.optionValue}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            ))}
                                        </div>

                                        {user && cart &&
                                            <div className="mb-3">
                                                <button className="cta"
                                                    disabled={this.checkVariants()}
                                                    onClick={() => {
                                                        cart && store(cart.id, product.barcode, this.state.amount, product.um, selectedVariants)
                                                    }}>
                                                    <span>{t("buy")}</span>
                                                </button>
                                            </div>
                                        }
                                        {user && !cart &&
                                            <div className="mb-3">
                                                <button className="cta"
                                                    onClick={() => {
                                                        // setProductToAdd(product, this.state.amount);
                                                        this.props.history.push("/cart")
                                                    }}>
                                                    <span>{t("buy")}</span>
                                                </button>
                                            </div>
                                        }
                                        {!user &&
                                            <div className="mb-3">
                                                <button className="cta"
                                                    onClick={() => {
                                                        // setProductToAdd(product, this.state.amount);
                                                        showLogin();
                                                    }}>
                                                    <span>{t("access")}</span>
                                                </button>
                                            </div>
                                        }
                                        {config.ENABLED_SLIST &&
                                            <div>
                                                {user &&
                                                    <div className="mb-3">
                                                        <button
                                                            className="custom-slist-button outline"
                                                            onClick={() => {
                                                                this.props.itemCreate(this.props.currentSlist.id, product.barcode, 1)
                                                            }}>
                                                            {t("smartlist.add")}
                                                        </button>
                                                    </div>
                                                }
                                                {!user &&
                                                    <div className="mb-3">
                                                        <button
                                                            className="custom-slist-button outline"
                                                            onClick={() => {
                                                                // setProductToAdd(product, this.state.amount);
                                                                document.getElementById('user').click();
                                                            }}>
                                                            {t("smartlist.add")}
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        <ProductInfo info={info} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    }
                </Translation>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        cart: state.cart.cart,
        favorites: state.slists.favorites,
        currentSlist: state.slists.currentSlist,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        store: (cartId, barcode, amount, um, variants) => dispatch(actionCreators.store(cartId, barcode, amount, um, variants)),
        setProductToAdd: (item, amount) => dispatch(actionCreators.setProductToAdd(item, amount)),
        getFavorites: () => dispatch(actionCreators.getFavorites()),
        addFavorite: (barcode) => dispatch(actionCreators.addFavorite(barcode)),
        deleteFavorite: (barcode) => dispatch(actionCreators.deleteFavorite(barcode)),
        itemCreate: (listId, barcode, amount) => dispatch(actionCreators.itemCreate(listId, barcode, amount)),
        showLogin: () => dispatch(actionCreators.showLogin(true)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Product))