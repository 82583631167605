import React, { Component } from 'react';
import StoreCard from '../../components/Cards/StoreCard';
import Store from './Store/Store';
import Pagination from '../../components/UI/Pagination/NewPagination';
import CustomInput from '../../components/UI/Input/CustomInput';
import { Row, Col, Accordion, Card } from "react-bootstrap"
import { storesService } from "../../services/basis/StoresService"
import { Translation } from 'react-i18next';
import { connect } from "react-redux"
import { debounce } from "lodash"
import * as actionCreators from "../../redux/store/actions/index"

class Stores extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            stores: [],
            selectedStore: null,
            page: 0,
            totalPages: null,
            query: "",
            withDistance: false
        }
    }

    componentDidMount() {
        storesService.stores(this.state.page, this.state.query)
            .then(response => {
                if (response.data.length > 0) {
                    this.setState({
                        stores: response.data,
                        selectedStore: response.data[0],
                        isLoaded: true,
                        totalPages: response.totalPages,
                        page: response.number,
                    })
                }
            }).catch((error) => {
                console.log(error);
            })
        if (navigator.geolocation) {
            if (!this.props.latitude && !this.props.longitude) {
                navigator.geolocation.getCurrentPosition(
                    pos => {
                        this.props.coordinate(
                            pos.coords.latitude,
                            pos.coords.longitude
                        )
                    }
                )
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.query !== this.state.query) {
            this.handleInput(this.state.query)
        }
    }

    readStoresByPosition = (page, latitude, longitude, query) => {
        if (latitude && longitude) {
            storesService.storesCoordinate(page, latitude, longitude, query)
                .then(response => {
                    if (response.data.length > 0) {
                        this.setState({
                            stores: response.data,
                            isLoaded: true,
                            totalPages: response.totalPages,
                            page: response.number,
                            withDistance: true
                        })
                    }
                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    readStores = (page, query) => {
        storesService.stores(page, query)
            .then(response => {
                this.setState({
                    stores: response.data,
                    isLoaded: true,
                    totalPages: response.totalPages,
                    page: response.number,
                    withDistance: false
                })
            }).catch((error) => {
                console.log(error);
            })
    }

    selectStore = (store) => {
        if (this.state.selectedStore) {
            const currentStoreId = this.state.selectedStore.id;
            if (store.id !== currentStoreId) {
                this.setState({ selectedStore: store })
            }
        } else {
            this.setState({ selectedStore: store })
        }
    }

    changePage = (page) => {
        if (page >= 0 && page < this.state.totalPages) {
            if (this.state.withDistance) {
                this.readStoresByPosition(page, this.props.latitude, this.props.longitude, this.state.query)
            } else {
                this.readStores(page, this.state.query);
            }
        }
    }

    onChange = (e) => {
        this.setState({ query: e.target.value });
    }

    handleInput = debounce((input) => {
        if (this.state.withDistance) {
            this.readStoresByPosition(0, this.props.latitude, this.props.longitude, input)
        } else {
            this.readStores(0, input)
        }
    }, 1000)

    render() {
        const { isLoaded, stores, selectedStore, page, totalPages, query, withDistance } = this.state;
        const { latitude, longitude } = this.props;
        if (isLoaded) {
            return (

                <Translation>
                    {t =>
                        <>
                            {/* Only on large device */}
                            <div className="container-fluid d-none d-xl-block">
                                <Row>
                                    <Col className="p-0" sm={12} md={12} lg={5}>
                                        <div className="pl-3">
                                            <div className="view-container">
                                                <div className="row px-0 mb-2">
                                                    <div className="col-md-8 px-0">
                                                        <CustomInput value={query} onChange={this.onChange} label="search" />
                                                    </div>
                                                </div>
                                                <div className="row px-0 mb-2">
                                                    <div className="col-md px-0">
                                                        <h6 className="cart-menu-title">{t("orderBy")}</h6>
                                                    </div>
                                                </div>
                                                <Row className="pb-3">
                                                    <button id="btn-name"
                                                        className={`store-button outline ${withDistance ? "" : "store-button-active"}`}
                                                        onClick={() => this.readStores(0, query)}>
                                                        {t("name")}
                                                    </button>
                                                    <button id="btn-distance" disabled={latitude && longitude ? false : true}
                                                        className={`store-button outline ${withDistance ? "store-button-active" : ""}`}
                                                        onClick={() => this.readStoresByPosition(0, latitude, longitude, query)}>
                                                        {t("distance")}
                                                    </button>
                                                </Row>
                                                {stores && stores.map((item) => (
                                                    <Row key={item.id}>
                                                        <StoreCard item={item} selectStore={this.selectStore} selectedStore={selectedStore} />
                                                    </Row>
                                                ))}
                                                <Row className="offset-3">
                                                    {totalPages > 1 &&
                                                        <Pagination
                                                            page={page}
                                                            totalPages={totalPages}
                                                            changePage={this.changePage}
                                                        />
                                                    }
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="p-0" style={{ backgroundColor: "#f7f7f7" }} sm={12} md={12} lg={7}>
                                        <Store selectedStore={selectedStore} />
                                    </Col>
                                </Row>
                            </div>

                            {/* Only on small device */}
                            <div className="view-container container-fluid d-xl-none">
                                <div className="row mb-2">
                                    <div className="col-md-8">
                                        <CustomInput value={query} onChange={this.onChange} label="search" />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md">
                                        <h6 className="cart-menu-title">{t("orderBy")}</h6>
                                    </div>
                                </div>
                                <Row className="pb-3">
                                    <div className="col">
                                        <button id="btn-name"
                                            className={`store-button outline ${withDistance ? "" : "store-button-active"}`}
                                            onClick={() => this.readStores(0, query)}>
                                            {t("name")}
                                        </button>
                                        <button id="btn-distance" disabled={latitude && longitude ? false : true}
                                            className={`store-button outline ${withDistance ? "store-button-active" : ""}`}
                                            onClick={() => this.readStoresByPosition(0, latitude, longitude, query)}>
                                            {t("distance")}
                                        </button>
                                    </div>
                                </Row>
                                <Accordion>
                                    {stores && stores.map((item, index) => (
                                        <Card key={item.id} className="mb-4">
                                            <Accordion.Toggle as={Card.Header} className={item.id === selectedStore.id ? "card-selected" : ""}
                                                eventKey={`${index}`} onClick={() => { this.selectStore(item) }}>
                                                <div className="row">
                                                    <div className="col-12 col-sm-3">
                                                        <h5 className="card-title title">{item.name}</h5>
                                                    </div>
                                                    <div className="col-12 col-sm-3 pb-sm-0 pb-2">
                                                        <p className="card-text">{item.address.address}</p>
                                                    </div>
                                                    <div className="col-5 col-sm-3 pb-sm-0 pb-3">
                                                        <p className="card-text card-text-muted">{item.phone && item.phone} {item.email && `- ${item.email}`}</p>
                                                    </div>
                                                    <div className="col-7 col-sm-3">
                                                        {item.distance &&
                                                            <p className="card-text-muted">
                                                                {t("distance")}: {`${item.distance} Km`}
                                                            </p>
                                                        }
                                                        {!item.distance &&
                                                            <p className="card-text card-text-muted">&nbsp;</p>
                                                        }
                                                    </div>
                                                </div>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={`${index}`}>
                                                <Card.Body>
                                                    <Store selectedStore={selectedStore} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    ))}
                                </Accordion>
                                {totalPages > 1 &&
                                    <Pagination
                                        page={page}
                                        totalPages={totalPages}
                                        changePage={this.changePage}
                                    />
                                }
                            </div>
                        </>
                    }
                </Translation>
            )
        } else {
            return null;
        }
    }
}

const mapStateToProps = state => {
    return {
        latitude: state.user.latitude,
        longitude: state.user.longitude,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        coordinate: (latitude, longitude) => dispatch(actionCreators.coordinate(latitude, longitude)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Stores);