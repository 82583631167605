import React from "react";
import BillingAddressCard from "./AddressCard/BillingAddressCard";
import BillingAddressPopup from "./AddressPopup/BillingAddressPopup";
import { Translation } from 'react-i18next';
import { addressesService } from "./Service"
import toaster from "../../../utils/toaster"
import { ReactComponent as AddIcon } from "../../../images/Add.svg"

import "./profile-addresses.styles.scss"

export default class BillingAddresses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addresses: [],
        };
    }

    componentDidMount() {
        addressesService.getBillingAddresses()
            .then(response => {
                console.log(response);
                this.setState({
                    addresses: response.data,
                })
            }).catch((error) => {
                console.log(error);
            })
    }

    newAddress = (address) => {
        addressesService.newBillingAddress(address)
            .then(response => {
                console.log(response);
                this.setState({
                    addresses: response.data,
                })
                if (address.id) {
                    toaster.standard("Indirizzo di fatturazione aggiornato con successo!")
                } else {
                    toaster.standard("Indirizzo di fatturazione aggiunto con successo!")
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    deleteAddress = (addressId) => {
        addressesService.deleteBillingAddress(addressId)
            .then(response => {
                console.log(response);
                this.setState({
                    addresses: response.data,
                })
                toaster.standard("Indirizzo di fatturazione eliminato con successo!")
            }).catch((error) => {
                console.log(error);
            })
    }

    render() {
        const { addresses } = this.state;
        return (
            <Translation>
                {t =>
                    <div className="profile-addresses">
                        <div className="profile-addresses-section">Indirizzi di fatturazione</div>
                        <BillingAddressPopup
                            action={this.newAddress}
                            title={t("addresses.new")}
                            button={t("save")}
                            isUpdate={false}
                            trigger={
                                <button className="add outline">
                                    <AddIcon className="icon" />
                                    <span className="text">{t("addresses.billingButton")}</span>
                                </button>
                            }
                        />
                        <hr />
                        <div className="profile-addresses-title">{t("addresses.title")}</div>
                        {addresses && addresses.map((address, index) => (
                            <BillingAddressCard
                                key={address.id}
                                address={address}
                                index={index}
                                action={this.newAddress}
                                deleteAddress={this.deleteAddress}
                            />
                        ))}
                    </div>
                }
            </Translation>
        );
    }
}

