import config from '../../config/config';
import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';

export const ecommerceService = {
    deliveryModes, searchProducts, orders, fetchOrder, availability, featuredProducts, abortOrder, productsTags
};

function deliveryModes() {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/deliveries`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
        })
            .then(handleResponse)
        // .then(checkResponse)
    );
}

function searchProducts(input, storeId, page, category, onlyPromotions, onlyBranded, tag) {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/products/${storeId}?q=${input}&p=${page}&category=${category}&onlyPromotions=${onlyPromotions}&onlyBranded=${onlyBranded}&tag=${tag}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
            credentials: "include"
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function orders(page) {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/orders?s=${config.maxSize}&p=${page}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
            credentials: "include"
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function fetchOrder(orderId) {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/orders/${orderId}/fetch`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
            credentials: "include"
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function availability() {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/slots/first/available`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
            credentials: "include"
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function featuredProducts(storeId) {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/products/${storeId}/featured`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function abortOrder(orderId) {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/cart/abort`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: 'include',
            body: JSON.stringify({
                orderId: orderId,
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    )
}

function productsTags() {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/productsTags`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
            credentials: "include"
        })
            .then(handleResponse)
        // .then(checkResponse)
    );
}

