import config from "../../../config/config"
import { handleResponse } from "../../../utils/handleResponse";
import { checkResponse } from "../../../utils/checkResponse";

export default function changePassword(oldPassword, newPassword) {
    return (
        fetch(`${config.baseUrl}/cli/profiles/password/change`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                oldPassword : oldPassword,
                newPassword: newPassword
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}