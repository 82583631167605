import React from "react"

export default class Pagination extends React.Component {
    render() {
        const { page, totalPages, changePage } = this.props;
        if (totalPages > 1) {
            return (
                <div className="pagination">
                    <span onClick={() => changePage(page - 1)}>&laquo;</span>
                    {[...Array(totalPages)].map((e, i) =>
                        <span onClick={() => changePage(i)} className={page === i ? "active" : ""} key={i}>{i + 1}</span>
                    )}
                    <span onClick={() => changePage(page + 1)}>&raquo;</span>
                </div>
            );
        } else return null;
    }
}