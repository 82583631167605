import React from "react"
import { Translation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { format, parseISO } from "date-fns";
import renderPrice from "../../utils/renderPrice";

export default class OrderCard extends React.Component {

    renderTotal = (item) => {
        let result;
        if (item.operatorCart && item.operatorCart.totalToPay) {
            result = `${renderPrice(item.operatorCart.totalToPay.toFixed(2))} \u20AC`
        } else {
            result = `${renderPrice(item.cart.totalToPay.toFixed(2))} \u20AC`
        }
        return result;
    }

    render() {
        const { item, selectOrder, currentOrder } = this.props;
        return (
            <Translation>
                {t =>
                    <div className={`card w-100 mb-3 order-status-${item.status}`} onClick={() => { selectOrder(item.id, item.status) }}>
                        <div className="row no-gutters">
                            <div className="col-md-12">
                                <div className={`card-body ${item.id === currentOrder.id ? "card-selected" : ""}`}>
                                    <Row style={{ marginBottom: "21px" }}>
                                        <Col sm={5}>
                                            <h5 className="card-text"><b>{item.cart.title}</b></h5>
                                        </Col>
                                        <Col sm={7}>
                                            <h5 className="card-text">
                                                {t("order.status")}:
                                                <span className="ml-1">
                                                    <b>{t(`order.status.${item.status}`)}</b>
                                                </span>
                                            </h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={5}>
                                            <p className="card-text">
                                                {t("total")}: <b>{this.renderTotal(item)}</b>
                                            </p>
                                        </Col>
                                        <Col sm={7}>
                                            <p className="card-text">
                                                {t("deliveryDate")}:    <b>{item.deliveryDate && format(parseISO(item.deliveryDate), "dd/MM/yyyy")}</b>
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}