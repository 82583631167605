import React from "react";
import Logo from "../../../../images/logo-smartbip.png";
import HeaderItem from "../HeaderItems/HeaderItem";
import HeaderCartItem from "../HeaderItems/HeaderCartItem";
import config from "../../../../config/config";
import { Link, withRouter } from "react-router-dom"
import { Translation } from 'react-i18next';
import MyStores from '../../../../images/MyStores.png';
import { ReactComponent as UserIcon } from '../../../../images/user-2.svg';
import { ReactComponent as CartIcon } from '../../../../images/landing-cart-sticky.svg';
import { ReactComponent as CategoryIcon } from '../../../../images/category.svg';

import "./new-header.styles.scss";

class NewHeader extends React.Component {
    render() {
        const { user, cart, searchInput, onChange, closeAll, order, showModalLogin, submitSearch, logoUrl } = this.props;
        return (
            <Translation>
                {t =>
                    <div className="header">
                        <div className="header-wrapper">
                            <div className="container-fluid">
                                <div className="row header-top">
                                    <div className="col pin">
                                        <a href="https://my-stores.it" target="_blank" rel="noopener noreferrer">
                                            <img src={MyStores} alt="my-stores" />
                                        </a>
                                    </div>
                                    {user && order &&
                                        <div className="col order">
                                            <nobr><div>
                                                {t("header.yourOrder")}
                                                <Link className="order-link" to="/orders">
                                                    <span> {order && order.code} </span>
                                                </Link>
                                                {t("header.orderNumberIs")} <span className="status">{order && t(`order.status.${order.status}`)}</span>
                                            </div></nobr>
                                        </div>
                                    }
                                    {user ?
                                        <div className="col user">
                                            <div className="user-logged-container" onClick={this.props.loginModal}>
                                                <div className="user-logged">
                                                    {user.firstName.charAt(0)}{user.lastName.charAt(0)}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="col user text-right">
                                            <UserIcon onClick={showModalLogin} />
                                        </div>
                                    }
                                </div>
                                <div className="row header-bottom">
                                    <div className="col">
                                        <div className="align-items-center logo">
                                            <Link to="/" className="link" onClick={closeAll}>
                                                {/* <Logo /> */}
                                                {/* <img src={Logo} alt="" /> */}
                                                <img src={logoUrl} alt="my-stores" />
                                                {/* <span>Logo shop</span> */}
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col search">
                                        <form onSubmit={e => submitSearch(e)}>
                                            <input type="search" onChange={onChange} value={searchInput}
                                                placeholder={t("header.mobileMobilePlaceHolder")}></input>
                                            <button type="submit" className="cta">
                                                <span>
                                                    {t("header.search")}</span>
                                            </button>
                                        </form>

                                    </div>
                                    <div className="col actions">
                                        <HeaderItem
                                            onClick={this.props.categoriesModal}
                                            actived={this.props.showCategories}>
                                            <CategoryIcon />
                                        </HeaderItem>
                                        {user &&
                                            <HeaderCartItem
                                                actived={this.props.showCategories}
                                                path="/cart"
                                                total={this.props.renderTotal(cart)}
                                                items={cart ? cart.itemsCount : "0"}
                                                closeAll={this.props.closeAll}>
                                                <CartIcon />
                                            </HeaderCartItem>
                                        }
                                        {!user &&
                                            <HeaderItem
                                                onClick={showModalLogin}>
                                                <CartIcon />
                                            </HeaderItem>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

export default withRouter(NewHeader);
