import React from "react"
import { Translation } from "react-i18next";

const BillingAddressSelect = ({ address, billingAddressId, selectAddress }) => {
    return (
        <Translation>
            {t =>
                <div className="checkout-billing-address">
                    <div className="radio-button">
                        <div className="custom-control custom-radio">
                            <input type="radio" className="custom-control-input" 
                                id={address.id} 
                                name={address.id}
                                value={address.id}
                                checked={address.id === billingAddressId}
                                onChange={() => selectAddress(address)}
                            />
                            <label className="custom-control-label" htmlFor={address.id} />
                        </div>
                    </div>
                    <div className="internal-container">
                        <div className="row">
                            <div className="col-sm-5 label">
                                {t("billingAddressSelect.businessName")}
                            </div>
                            <div className="col-sm-6 info">
                                {address && address.companyName}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-5 label">
                                {t("billingAddressSelect.vatNumber")}
                            </div>
                            <div className="col-sm-6 info">
                                {address && address.vatCode}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-5 label">
                                {t("billingAddressSelect.ssnCode")}
                            </div>
                            <div className="col-sm-6 info">
                                {address && address.ssnCode}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-5 label">
                                {t("billingAddressSelect.address")}
                            </div>
                            <div className="col-sm-6 info">
                                {address && address.address}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-5 label">
                                {t("billingAddressSelect.phoneNumber")}
                            </div>
                            <div className="col-sm-6 info">
                                {address && address.phone}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-5 label">
                                {t("billingAddressSelect.pec")}
                            </div>
                            <div className="col-sm-6 info">
                                {address && address.pec}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-5 label">
                                {t("billingAddressSelect.destinationCode")}
                            </div>
                            <div className="col-sm-6 info">
                                {address && address.recipientCode}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Translation>
    );

}

export default BillingAddressSelect;