import config from "../../config/config"
import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';

export const ecommerceCartsService = {
    current, open, openCarts, store, review, remove, freeze, abort, move, copyOrder, freezeCheck
}

function current() {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/cart/current`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
            credentials: 'include',
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function open(deliveryMode, storeId, addressId) {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/cart/open`, {
            method: 'POST',
            headers: { "Content-Type": "application/json", },
            mode: "cors",
            credentials: 'include',
            body: JSON.stringify({
                storeId: storeId,
                deliveryMode: deliveryMode,
                addressId: addressId ? addressId : "",
                terminalType: "WEB",
                terminalId: "9095bfc3-2dad-44dc-89e0-b9f232542f32",
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    )
}

function openCarts() {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/carts/open`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: 'include',
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function store(cartId, barcode, amount, um, variants) {
    console.log(variants);
    let body = null;
    if (um === "PZ") {
        body = {
            cartId: cartId,
            barcode: barcode,
            amount: amount,
            preview: false,
            cartItemVariants: variants
        }
    } else {
        body = {
            cartId: cartId,
            barcode: barcode,
            weight: amount,
            preview: false,
            cartItemVariants: variants
        }
    }
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/cart/store`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: 'include',
            body: JSON.stringify(body)
        })
            .then(handleResponse)
            .then(checkResponse)
    )
}

function remove(cartId, barcode) {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/cart/delete`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: 'include',
            body: JSON.stringify({
                cartId: cartId,
                barcode: barcode,
                preview: false
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    )
}

function review(cartId) {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/cart/review`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: 'include',
            body: JSON.stringify({
                cartId: cartId,
                refresh: false
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    )
}

function freeze(data, cartId, paymentMode) {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/cart/freeze`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: 'include',
            body: JSON.stringify({
                cartId: cartId,
                profileEmail: data.profileEmail,
                profileMobilePhone: data.profileMobilePhone,
                firstName: data.profileFirstName,
                lastName: data.profileLastName,
                timeSlotId: data.timeSlotId,
                paymentMode: paymentMode,
                notes: data.notes,
                invoice: data.invoice,
                billingAddressId: data.billingAddressId,
                enableProductsReplacement: data.enableProductsReplacement,
                clientWeb: true,
                // timeSlotDay: data.day
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    )
}

function abort(cartId) {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/cart/abort`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: 'include',
            body: JSON.stringify({
                cartId: cartId,
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    )
}

function move(cartId, storeId) {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/cart/move`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: 'include',
            body: JSON.stringify({
                cartId: cartId,
                storeId: storeId,
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    )
}

function copyOrder(orderId) {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/cart/copy`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: 'include',
            body: JSON.stringify({
                orderId: orderId,
                addressId: "",
                terminalType: "WEB",
            })
        })
            .then(handleResponse)
        // .then(checkResponse)
    )
}

function freezeCheck(cartId, preview) {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/cart/freeze/check`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: 'include',
            body: JSON.stringify({
                cartId: cartId,
                preview: preview
            })
        })
            .then(handleResponse)
        // .then(checkResponse)
    )
}