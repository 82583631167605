import config from "../../config/config"
import { handleResponse } from "../../utils/handleResponse";
// import { checkResponse } from "../../utils/checkResponse";


export default function categories() {
    return (
        fetch(`${config.baseUrl}/cli/categories/byStore?storeid=${config.DEFAULT_STORE_ID}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
        })
            .then(handleResponse)
            // .then(checkResponse)
    );
}