import React from "react"
import { Translation } from "react-i18next";

import "./validate-input.styles.scss";

const ValidateInput = ({ value, watch, label, register, errors, ...otherProps }) => { 
    return (
        <Translation>
            {t =>
                <div className="group">
                    <input className={`form-input ${errors ? "input-required" : ""}`}
                        defaultValue={value}
                        ref={register}
                        {...otherProps}
                    />
                    {
                        label ?
                            (<label className={`${(watch && watch.length > 0) || 
                            (value && value.length > 0 && watch === undefined) ? "shrink" : ""} form-input-label`}>
                                {t(label)}
                            </label>)
                            : null
                    }
                </div>
            }
        </Translation>
    );
}

export default ValidateInput;