import React from "react";
import { Translation } from 'react-i18next';
import { withRouter } from "react-router-dom"

class BookingHeader extends React.Component {
    render() {
        // define css classes depending on current path
        const currentPath = this.props.history.location.pathname;
        let step1 = "step-current"
        let step2 = ""
        let confirmCss = ""
        switch (currentPath) {
            case "/checkout/data":
                step1 = "step-completed";
                step2 = "step-current";
                break;
            case "/checkout/summary":
                step1 = "step-completed";
                step2 = "step-completed";
                break;
            case "/checkout/confirm":
                step1 = "step-completed";
                step2 = "step-completed";
                confirmCss = "step-current";
                break;

            default: step1 = `step-current`;
        }

        return (
            <Translation>
                {t =>
                    <div className="booking-header">
                        <div className="d-flex justify-content-between controls">
                            <span className={`${step1} step`}>{t("step1")}</span>
                            <span className={`${step2} step`}>{t("step2")}</span>
                            <span className={`${confirmCss} step`}>{t("confirm")}</span>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

export default withRouter(BookingHeader);