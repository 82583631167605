import { format } from "date-fns";

export default function dayOfWeek(days) {

    let dates = []
    // create array of dates, starting from tomorrow and for the days.length array of input
    // object array structure example
    // {
    //    name: Monday
    //    dayNumber: 15
    //    month: April
    //    day: days from 1 to 7 corresponding to Sunday to Monday
    // }
    for (let i = 0; i < 10; i++) {
        let el = new Date()
        el.setDate(el.getDate() + i);
        let dayOfWeek = el.toLocaleDateString("en-EN", { weekday: 'long' })
        let dayNumber = el.toLocaleDateString("en-EN", { day: 'numeric' })
        let month = el.toLocaleDateString("en-EN", { month: 'long' })
        let date = format(el, "yyyy-MM-dd")

        switch (dayOfWeek) {
            case ('Sunday'):
                dates.push({
                    name: dayOfWeek,
                    day: el.getDay() + 1,
                    dayNumber,
                    month,
                    date
                })
                break
            case ('Monday'):
                dates.push({
                    name: dayOfWeek,
                    day: el.getDay() + 1,
                    dayNumber,
                    month,
                    date
                })
                break
            case ('Tuesday'):
                dates.push({
                    name: dayOfWeek,
                    day: el.getDay() + 1,
                    dayNumber,
                    month,
                    date
                })
                break
            case ('Wednesday'):
                dates.push({
                    name: dayOfWeek,
                    day: el.getDay() + 1,
                    dayNumber,
                    month,
                    date
                })
                break
            case ('Thursday'):
                dates.push({
                    name: dayOfWeek,
                    day: el.getDay() + 1,
                    dayNumber,
                    month,
                    date
                })
                break
            case ('Friday'):
                dates.push({
                    name: dayOfWeek,
                    day: el.getDay() + 1,
                    dayNumber,
                    month,
                    date
                })
                break
            case ('Saturday'):
                dates.push({
                    name: dayOfWeek,
                    day: el.getDay() + 1,
                    dayNumber,
                    month,
                    date
                })
                break
        }
    }
    if (typeof days === 'number') {
        let el = dates.filter(date => { if (date.day === days) return date })[0]
        return el
    } else {
        // cycling inside the input array of days and search for the FIRST corresponding element found
        let output = []
        for (let i = 0; i <= days.length; i++) {
            let el = dates.filter(date => { if (date.day === days[i]) return date })
            if (el && el[0]) {
                let elIndex = dates.indexOf(el[0])
                output.push(el[0]) // pushing the object with all datas needed into the output array
                if (elIndex !== -1) dates.splice(elIndex, 1) // remove to element found to avoid duplicates
            }
        }

        return output
    }
}

export function dayNumber(n) {
    let day = "";
    switch (n) {
        case 1: day = "Domenica"; break;
        case 2: day = "Lunedì"; break;
        case 3: day = "Martedì"; break;
        case 4: day = "Mercoledì"; break;
        case 5: day = "Giovedì"; break;
        case 6: day = "Venerdì"; break;
        case 7: day = "Sabato"; break;
        default: day = "";
    }
    return day
}