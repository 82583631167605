import React from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Translation } from 'react-i18next';
import { storeIcons } from "../../../../utils/renderStoreIcon"

export default class StoreInfo extends React.Component {

    render() {
        const { selectedStore } = this.props;
        if (selectedStore) {
            return (
                <Translation>
                    {t =>
                        <Row>
                            {(selectedStore.phone || selectedStore.email || selectedStore.webUrl) &&
                                <Col sm={4}>
                                    <div className="mb-4">
                                        <div className="product-info-title">{t("contacts")}</div>
                                        <div className="product-info">
                                            {selectedStore.phone &&
                                                <div>
                                                    <h4>{t("storePhone")}:</h4>
                                                    <p>{selectedStore.phone}</p>
                                                </div>
                                            }
                                            {selectedStore.email &&
                                                <div>
                                                    <h4>{t("storeEmail")}:</h4>
                                                    <p>{selectedStore.email}</p>
                                                </div>
                                            }
                                            {selectedStore.webUrl &&
                                                <div>
                                                    <h4>{t("webUrl")}:</h4>
                                                    <p>{selectedStore.webUrl}</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Col>
                            }
                            {selectedStore.timetables &&
                                <Col sm={4}>
                                    <div className="mb-4">
                                        <div className="product-info-title">{t("time")}</div>
                                        <div className="product-info">
                                            {selectedStore.timetables.map((timetable, index) => (
                                                <p key={index}>{timetable}</p>
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                            }
                            {selectedStore.openings &&
                                <Col sm={4}>
                                    <div className="mb-4">
                                        <div className="product-info-title">{t("openings")}</div>
                                        <div className="product-info">
                                            {selectedStore.openings.map((opening, index) => (
                                                <p key={index}>{opening}</p>
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                            }
                            {selectedStore.services &&
                                <Col sm={4}>
                                    <div className="mb-4">
                                        <div className="product-info-title">{t("services")}</div>
                                        <div className="product-info">
                                            {selectedStore.services.map((service, index) => (
                                                storeIcons.renderServices(service.toLowerCase())
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                            }
                            {selectedStore.departments &&
                                <Col sm={4}>
                                    <div className="mb-4">
                                        <div className="product-info-title">{t("departments")}</div>
                                        <div className="product-info">
                                            {selectedStore.departments.map((department, index) => (
                                                storeIcons.renderDepartments(department.toLowerCase())
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                            }
                        </Row>
                    }
                </Translation>
            );
        } else {
            return null;
        }
    }
}



