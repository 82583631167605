import config from "../../config/config"
import { handleResponse } from "../../utils/handleResponse";
import { checkResponse } from "../../utils/checkResponse";

export default function resetPassword(email) {
    return (
        fetch(`${config.baseUrl}/cli/profiles/password`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
            body: JSON.stringify({
                tid : "01",
                email: email
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}