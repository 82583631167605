import React from "react";
import { Translation } from 'react-i18next';
import { withRouter } from "react-router-dom"
import GoBackButton from "../../../components/UI/Buttons/GoBackButton";

class CheckoutHeader extends React.Component {
    changePage = () => {
        const currentPath = this.props.history.location.pathname;
        let path = "";
        switch (currentPath) {
            case "/checkout":
                path = "/checkout/data"; break
            case "/checkout/data":
                path = "/checkout/summary"; break
            default: path = "/checkout";
        }
        this.props.history.push(path);
    }

    render() {
        // define css classes depending on current path
        const currentPath = this.props.history.location.pathname;
        let availabilityCss = "checkout-controls-step-current"
        let summaryCss = ""
        let confirmCss = ""
        switch (currentPath) {
            case "/checkout/data":
                availabilityCss = "checkout-controls-step-completed";
                summaryCss = "checkout-controls-step-current";
                break;
            case "/checkout/summary":
                availabilityCss = "checkout-controls-step-completed";
                summaryCss = "checkout-controls-step-completed";
                break;
            case "/checkout/confirm":
                availabilityCss = "checkout-controls-step-completed";
                summaryCss = "checkout-controls-step-completed";
                confirmCss = "checkout-controls-step-current";
                break;

            default: availabilityCss = `checkout-controls-step-current`;
        }

        return (
            <Translation>
                {t =>
                    <>
                        <GoBackButton goBack={() => { this.props.history.goBack() }}>
                            {t("goBack")}
                        </GoBackButton>
                        <div className="checkout-header">
                            <div className="d-flex justify-content-between checkout-controls">
                                <span className={`${availabilityCss} checkout-controls-step`}>{t("availability")}</span>
                                <span className={`${summaryCss} checkout-controls-step`}>{t("summary")}</span>
                                <span className={`${confirmCss} checkout-controls-step`}>{t("confirm")}</span>
                            </div>
                        </div>
                    </>
                }
            </Translation>
        );
    }
}

export default withRouter(CheckoutHeader);