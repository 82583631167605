import React from "react"
import Form from 'react-bootstrap/Form'
import { Translation } from "react-i18next";

export default class CustomInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFocused: false
        }
    }

    componentDidMount() {
        if (this.props.value !== "") {
            this.setState({
                isFocused: true
            })
        }
    }

    onFocus = () => {
        this.setState({
            isFocused: true
        })
    }

    onBlur = () => {
        if (this.props.value === "") {
            this.setState({
                isFocused: false
            })
        }
    }

    render() {
        const cssClass = this.state.isFocused ? "custom-input-placeholder custom-input-placeholder-elevated" : "custom-input-placeholder";
        return (
            <Translation>
                {t =>
                    <Form.Group>
                        <Form.Label className={cssClass} >{t(this.props.label)}</Form.Label>
                        <Form.Control
                            disabled = {this.props.disabled}
                            onChange={this.props.onChange}
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            className="custom-input"
                            name={this.props.label}
                            value={this.props.value}
                            type={this.props.type} />
                    </Form.Group>
                }
            </Translation>
        );
    }
}

