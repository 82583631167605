import React from "react";
import Popup from "reactjs-popup";
// import { ReactComponent as FacebookIcon } from "../../../images/facebook-cta.svg"
import { ReactComponent as EmailIcon } from "../../../images/Email.svg"
import { ReactComponent as PasswordIcon } from "../../../images/Password.svg"
import { useForm } from 'react-hook-form'
import { Translation } from "react-i18next";
import { connect } from "react-redux"
import * as actionCreators from "../../../redux/store/actions/index"

import "./login-popup.styles.scss";
import { Link } from "react-router-dom";

function LoginPopup(props) {

    const { register, handleSubmit, errors, watch } = useForm({
        mode: 'onBlur',
    });

    const onSubmit = data => {
        console.log(data);
        props.login(data);
    }

    return (
        <Translation>
            {t =>
                <Popup
                    lockScroll={true}
                    contentStyle={popupStyle}
                    closeOnDocumentClick={false}
                    open={props.open}
                    onClose={props.closePopup}
                    modal
                >
                    {close => (
                        <div className="login-popup">
                            <div id="close-popup" className="close" onClick={close}>
                                &times;
                            </div>
                            <div className="login-popup-header">
                                {t("loginPopup.login")}
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off"
                                className={`login-popup-form needs-validation ${errors ? "had-validation" : ""}`}>
                                <div className="login-popup-content">
                                    <div className="row no-gutters">
                                        <div className="col-12"
                                        // style={{ borderRight: "1px solid #d8d8d8" }}
                                        >
                                            {/* <div className="login-popup-title">Usa le tue credenziali</div> */}
                                            <div className="input-container">
                                                <EmailIcon className="icon" />
                                                <input
                                                    name="username"
                                                    placeholder={t("email")}
                                                    type="email"
                                                    watch={watch("username")}
                                                    ref={register({
                                                        required: "Campo obbligatorio",
                                                        // pattern: {
                                                        //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                        //     message: "Indirizzo email non valido"
                                                        // }
                                                    })}
                                                />
                                            </div>
                                            <div className="input-container">
                                                <PasswordIcon className="icon" />
                                                <input
                                                    type="password"
                                                    name="password"
                                                    placeholder="Password"
                                                    watch={watch("password")}
                                                    ref={register({
                                                        required: "Campo obbligatorio",
                                                    })}
                                                >
                                                </input>
                                            </div>
                                            <div className="login-popup-subtitle" onClick={close}>
                                                {t("loginPopup.forgotPassword")}
                                                 <Link to="/reset"><span className="bold">{t("loginPopup.clickHere")}</span></Link>
                                            </div>
                                            <button className="cta"><span>{t("loginPopup.login")}</span></button>
                                            <div className="login-popup-subtitle" onClick={close}>
                                                {t("loginPopup.notHavingAnAccount")}
                                                 <Link to="/signup"><span className="bold">{t("loginPopup.register")}</span></Link>
                                            </div>
                                        </div>
                                        {/* <div className="col-6">
                                            <div className="login-popup-title">Usa i social</div>
                                            <button className="facebook-cta">
                                                <FacebookIcon />
                                                <span>Accedi con Facebook</span>
                                            </button>
                                        </div> */}
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}
                </Popup>
            }
        </Translation >
    );
}

const popupStyle = {
    borderRadius: "8px",
    border: "none",
    boxShadow: `0 0 23px 10px rgba(0, 0, 0, 0.24)`,
    cursor: "unset"

}

const mapDispatchToProps = dispatch => {
    return {
        login: (data) => dispatch(actionCreators.login(data)),
    };
}

export default connect(null, mapDispatchToProps)(LoginPopup);