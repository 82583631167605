import React from "react";
import Header from "../Header/ResponsiveHeader";
import Footer from "../Footer/Footer";

import LoadingOverlay from 'react-loading-overlay';
import { connect } from "react-redux"

class MainTemplate extends React.Component {
    render() {
        return (
            <LoadingOverlay
                active={this.props.showOverlay}
                spinner
            >
                <div className="main-container">
                    <Header />
                    <div className="content">
                        {this.props.children}
                    </div>
                    <Footer />
                </div>
            </LoadingOverlay>
        );
    }
}

const mapStateToProps = state => {
    return {
        showOverlay: state.user.showOverlay
    };
}

export default connect(mapStateToProps)(MainTemplate);