import React from "react";
import { useForm } from 'react-hook-form'
import Popup from "reactjs-popup";
import { Translation } from "react-i18next";

import "./address-popup.styles.scss";
import ValidateInput from "../../../../components/UI/Input/ValidateInput";

function AddressPopup(props) {

    const { register, handleSubmit, errors, watch } = useForm({
        mode: 'onBlur',
    });

    const onSubmit = data => {
        console.log(data);
        props.action(data);
        document.getElementById("close-popup").click();
    }

    return (
        <Translation>
            {t =>
                <Popup lockScroll={true} offsetY={25} contentStyle={popupStyle} trigger={props.trigger} modal>
                    {close => (
                        <div className="address-popup">
                            <div id="close-popup" className="close" onClick={close}>
                                &times;
                            </div>
                            <div className="address-popup-header">
                                {props.title}
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off"
                                className={`address-popup-form needs-validation ${errors ? "had-validation" : ""}`}>
                                <div className="address-popup-content">
                                    <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />
                                    <div className="row popup-row">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="vatCode"
                                                label="Partita iva*"
                                                type="text"
                                                value={props.address && props.address.vatCode}
                                                watch={watch("vatCode")}
                                                errors={errors.vatCode}
                                                register={register({
                                                    required: "true"
                                                })}
                                            />
                                        </div>
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="ssnCode"
                                                label="Codice fiscale"
                                                type="text"
                                                value={props.address && props.address.ssnCode}
                                                watch={watch("ssnCode")}
                                                errors={errors.ssnCode}
                                                register={register}
                                            />
                                        </div>
                                    </div>
                                    <div className="row popup-row">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="companyName"
                                                label="Ragione sociale*"
                                                type="text"
                                                value={props.address && props.address.companyName}
                                                watch={watch("companyName")}
                                                errors={errors.companyName}
                                                register={register({
                                                    required: "true"
                                                })}
                                            />
                                        </div>
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="phone"
                                                label="Telefono*"
                                                type="text"
                                                value={props.address && props.address.phone}
                                                watch={watch("phone")}
                                                errors={errors.phone}
                                                register={register({
                                                    required: "true"
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row popup-row">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="address"
                                                label="Indirizzo*"
                                                type="text"
                                                value={props.address && props.address.address}
                                                watch={watch("address")}
                                                errors={errors.address}
                                                register={register({
                                                    required: "true"
                                                })}
                                            />
                                        </div>
                                        <div className="col-xs-6 col-sm-2 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="streetNumber"
                                                label="Civico*"
                                                type="text"
                                                value={props.address && props.address.streetNumber}
                                                watch={watch("streetNumber")}
                                                errors={errors.streetNumber}
                                                register={register({
                                                    required: "true"
                                                })}
                                            />
                                        </div>
                                        <div className="col-xs-6 col-sm-4 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="zip"
                                                label="Cap*"
                                                type="text"
                                                value={props.address && props.address.zip}
                                                maxLength={5}
                                                watch={watch("zip")}
                                                errors={errors.zip}
                                                register={register({
                                                    required: "true",
                                                    maxLength: 5, minLength: 5
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row popup-row">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="city"
                                                label="Città*"
                                                type="text"
                                                value={props.address && props.address.city}
                                                watch={watch("city")}
                                                errors={errors.city}
                                                register={register({
                                                    required: "true"
                                                })}
                                            />
                                        </div>
                                        <div className="col-xs-6 col-sm-2 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="county"
                                                label="Provincia*"
                                                type="text"
                                                value={props.address && props.address.county}
                                                maxLength={2}
                                                watch={watch("county")}
                                                errors={errors.county}
                                                register={register({
                                                    required: "true",
                                                    maxLength: 2, minLength: 2
                                                })}
                                            />
                                        </div>
                                        <div className="col-xs-6 col-sm-4 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="fax"
                                                label="Fax"
                                                type="text"
                                                value={props.address && props.address.fax}
                                                maxLength={5}
                                                watch={watch("fax")}
                                                errors={errors.fax}
                                                register={register}
                                            />
                                        </div>
                                        {props.isUpdate &&
                                            <div className="d-none">
                                                <ValidateInput
                                                    name="id"
                                                    type="text"
                                                    value={props.address && props.address.id}
                                                    watch={watch("id")}
                                                    errors={errors.id}
                                                    register={register({
                                                        required: "true"
                                                    })}
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div className="row popup-row">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="pec"
                                                label="PEC"
                                                type="text"
                                                value={props.address && props.address.pec}
                                                watch={watch("pec")}
                                                errors={errors.pec}
                                                register={register}
                                            />
                                        </div>
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="recipientCode"
                                                label="Codice destinatario*"
                                                type="text"
                                                value={props.address && props.address.recipientCode}
                                                watch={watch("recipientCode")}
                                                errors={errors.recipientCode}
                                                register={register}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="address-popup-actions">
                                    {props.isUpdate &&
                                        <button className="cta delete" onClick={() => {
                                            props.deleteAddress(props.address && props.address.id)
                                            close();
                                        }}>
                                            <span>{t("addresses.delete")}</span>
                                        </button>
                                    }
                                    <button type="submit" className="cta">
                                        <span>{props.button}</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}
                </Popup>
            }
        </Translation>
    );
}

const popupStyle = {
    borderRadius: "15px"
}

export default AddressPopup;