import config from "../../../../config/config"
import { handleResponse } from "../../../../utils/handleResponse";
import { checkResponse } from "../../../../utils/checkResponse";

export default function timeslots(cartId, day) {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/slots/${cartId}?weekDay=${day}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
            credentials: "include"
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}