import config from "../../config/config"
import { handleResponse } from "../../utils/handleResponse";
import { checkResponse } from "../../utils/checkResponse";

export const paymentsService = {
    paymenthMethods, removePaymentMethod
}

function paymenthMethods() {
    return (
        fetch(`${config.baseUrl}/cli/pay/paymentmethod`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
            credentials: "include"
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function removePaymentMethod() {
    return (
        fetch(`${config.baseUrl}/cli/pay/paymentmethod/delete`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: 'include',
            body: JSON.stringify({
                paymentMethodType: "STRIPE"
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    )
}