import React from "react";
import PaymentsLogo from "../../images/payment_circuits.png"
import { paymentsService } from "./Service";
import { Translation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import config from "../../config/config";
// import { Link } from "react-router-dom";

export default class Payments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentMethods: [],
            isBoarded: false
        };
    }

    componentDidMount() {
        paymentsService.paymenthMethods()
            .then(response => {
                console.log(response);
                if (response.data.length > 0) {
                    response.data.forEach(method => {
                        if (method.paymentMethodType === "BNL_POSITIVITY") {
                            this.setState({ isBoarded: true })
                        }
                    });
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    removePaymenMethod = () => {
        paymentsService.removePaymentMethod()
            .then(response => {
                console.log(response);
                this.setState({
                    paymentMethods: response.data,
                    isBoarded: false
                })
            }).catch((error) => {
                console.log(error);
            })
    }

    render() {
        const { isBoarded } = this.state;
        return (
            <Translation>
                {t =>
                    <div className="payments-container w-100">
                        <div className="view-container">
                            <p className="title">
                                {t('payments')}
                            </p>
                            <p>
                                <span>{t('payments.title')}</span> <br />
                                <span>{t('payments.subTitle')}</span>
                            </p>
                            {!isBoarded &&
                                <div className="text-center">
                                    <div className="text-muted pb-4">
                                        <FontAwesomeIcon icon="credit-card" size="5x" />
                                    </div>
                                    <div className="pb-2 text-danger">
                                        {t("payment.textDanger")}
                                    </div>
                                    <div className="pb-4">
                                        {/* <Link to="/payments/boarding">
                                            <button style={{ width: "250px" }} className="custom-button outline">Associa la tua carta</button>
                                        </Link> */}
                                        <a href={`${config.baseUrl}/cli/pay/bnl/boarding`} target="_blank" rel="noopener noreferrer">
                                            <button style={{ width: "250px" }} className="custom-button outline">Associa la tua carta</button>
                                        </a>
                                    </div>
                                    <img style={{ width: "300px" }} src={PaymentsLogo} alt="" />
                                </div>
                            }
                            {isBoarded &&
                                <div className="text-center">
                                    <div className="text-success pb-4">
                                        <FontAwesomeIcon icon="credit-card" size="5x" />
                                    </div>
                                    <div className="pb-2 text-success">
                                        {t("payment.textSuccess")}
                                    </div>
                                    <div className="pb-4">
                                        <button style={{
                                            width: "250px",
                                            backgroundColor: "#f8d7da",
                                            color: "#721c24",
                                            borderColor: "#f5c6cb"
                                        }}
                                            onClick={this.removePaymenMethod}
                                            className="custom-button outline">
                                            {t("payment.remove")}
                                            </button>
                                    </div>
                                    <img style={{ width: "300px" }} src={PaymentsLogo} alt="" />
                                </div>
                            }
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}
