import React from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Placeholder from "../../images/product-placeholder.png"
import * as actionCreators from "../../redux/store/actions/index"
import { connect } from "react-redux"
import { Translation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom"

class Favorites extends React.Component {
    render() {
        const { favorites } = this.props;
        return (
            <Translation>
                {t =>
                    <div className="favorite-container w-100">
                        <div className="view-container">
                            <span className="cart-menu-title mb-4">{t("favorites")}</span>
                            <div className="d-none d-sm-block">
                                <Row className="cart-heading mb-4">
                                    <Col sm={1}>
                                        <span>{t("products")}</span>
                                    </Col>
                                    <Col sm={5}>
                                        <span className="cart-product-description">
                                            {t("description")}
                                        </span>
                                    </Col>
                                    <Col sm={2}>
                                        <span>{t("barcode")}</span>
                                    </Col>
                                    <Col sm={2}>
                                        <span>{t("delete")}</span>
                                    </Col>
                                </Row>
                            </div>
                            {favorites.map(item => (
                                <div key={item.id} className="row mb-3 favorite-products">
                                    <div className="col-3 col-sm-1">
                                        <Link className="link"
                                            to={{ pathname: `/product/${item.barcode}`, state: { product: item } }}>
                                            <img src={item.imageUrl ? item.imageUrl : Placeholder} alt=""
                                                onError={(e) => { e.target.src = Placeholder }}
                                            />
                                        </Link>
                                    </div>

                                    <div className="col-7 col-sm-5 favorite-products-text">
                                        <Link className="link"
                                            to={{ pathname: `/product/${item.barcode}`, state: { product: item } }}>
                                            <p className="cart-product-description">{item.description}</p>
                                        </Link>
                                    </div>
                                    <div className="col-2 col-sm-2 favorite-products-text d-none d-sm-block">
                                        <span>{item.barcode}</span>
                                    </div>
                                    <div className="col-1 col-sm-2 favorite-products-text">
                                        <button className="button-clean outline primary-text ml-3"
                                            onClick={() => this.props.deleteFavorite(item.barcode)}>
                                            <FontAwesomeIcon icon="times-circle" size="1x" />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        favorites: state.slists.favorites,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        getFavorites: () => dispatch(actionCreators.getFavorites()),
        deleteFavorite: (barcode) => dispatch(actionCreators.deleteFavorite(barcode)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Favorites);