import React from "react"
import { Translation } from "react-i18next";

import "./smartlist-header.styles.scss"

class SmartlistHeader extends React.Component {
    handleChange = (event) => {
        this.props.fetchSlist(event.target.value)
    };

    render() {
        const { slists, slist, renderRename, renderAbort, renderCreate, renderCopy } = this.props;
        return (
            <Translation>
                {t =>
                    <>
                        {/* Only on desktop device */}
                        <div className="smartlist-header d-none d-lg-block">
                            <div className="content py-3">
                                <select onChange={this.handleChange}>
                                    {slists.map((item) => (
                                        <option key={item.id} value={item.id}
                                            selected={item.id === slist.id ? true : false}>
                                            {item.title}
                                        </option>
                                    ))}
                                </select>
                                <span className="actions">
                                    {slist && renderRename(slist.id)}
                                    {slist && renderCopy(slist.id)}
                                    {slist && renderAbort(slist.id)}
                                </span>
                                {renderCreate()}
                            </div>
                        </div>

                        {/* Only on mobile device */}
                        <div className="smartlist-header container-fluid d-block d-lg-none">
                            <div className="row">
                                <div className="col-10 col-sm-8 col-md-8 py-3">
                                    <select onChange={this.handleChange}>
                                        {slists.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.title}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-2 col-sm-4 col-md-4 py-3">
                                    {renderCreate()}
                                </div>
                            </div>
                        </div>
                    </>
                }
            </Translation>
        );
    }
}

export default SmartlistHeader;