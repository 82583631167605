import React from "react";
import Map from "../../../components/UI/Maps/Map";
import { Translation } from 'react-i18next';
import StoreInfo from "./StoreInfo/StoreInfo";

export default class Store extends React.Component {

    render() {
        const { selectedStore } = this.props;
        if (selectedStore) {
            return (
                <Translation>
                    {t =>
                        <>
                            <div className="text-center mt-3 mb-3">
                                <span className="title">{selectedStore.name}</span>
                                <div className="mt-2 primary-text">
                                    <a href={`https://maps.google.com/?q=${selectedStore.address.latitude},${selectedStore.address.longitude}`} target="__blank">
                                        {selectedStore.address && selectedStore.address.address}
                                    </a>
                                </div>
                            </div>
                            {selectedStore.address && selectedStore.address.latitude && selectedStore.address.longitude &&
                                <div className="map-container d-none d-sm-flex">
                                    <Map lat={selectedStore.address.latitude} lng={selectedStore.address.longitude} zoom={18} />
                                </div>
                            }
                            <div className="view-container">
                                <div className="container-fluid g-padding">
                                    <StoreInfo selectedStore={selectedStore} />
                                </div>
                            </div>
                        </>
                    }
                </Translation>
            );
        } else {
            return null;
        }
    }
}



