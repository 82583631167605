import React from "react";
import { Route, Switch } from 'react-router-dom';
import Row from "react-bootstrap/Row"
import BookingHeader from "./BookingHeader/BookingHeader";
import SignupBanner from "../../images/signup-banner.png"
// import Disponibility from "./Disponibility/Disponibility";
// import UserData from "./UserData/UserData";
// import Confirm from "./Confirm/Confirm";
import { Translation } from 'react-i18next';
import { connect } from "react-redux"
import * as actionCreators from "../../redux/store/actions/index"

import "./booking.styles.scss"

class Booking extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        if (this.props.checkoutInfo) this.props.saveCheckoutInfo(null)
    }

    selectDay = (day) => {

    }

    selectTimeslot = (timeSlotId) => {
        if (this.state.timeSlotId) {
            if (document.getElementById(this.state.timeSlotId)) {
                document.getElementById(this.state.timeSlotId).classList.remove("pill-active");
            }
        }
        this.setState({ timeSlotId: timeSlotId })
        document.getElementById(timeSlotId).classList.add("pill-active");
    }

    selectProfileInfo = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    freeze = (cartId) => {
        this.props.freeze(this.state, cartId, this.state.paymentMethod);
    }

    render() {
        const { cart } = this.props;
        return (
            <Translation>
                {t =>
                    <div className="booking">
                        <div className="booking-banner" style={{ backgroundImage: `url(${SignupBanner})` }} alt="" />
                        <div className="text-center">
                            <span className="booking-title">{t("booking.title")}</span>
                        </div>
                        <BookingHeader data={this.state} />

                        <div className="booking-view mx-auto">
                            <span className="booking-subtitle">Seleziona il tuo barbiere di fiducia</span>
                            <div className="grid_1">
                                <button className="outline">Nome Barbiere</button>
                                <button className="outline">Nome Barbiere</button>
                                <button className="outline">Nome Barbiere</button>
                                <button className="outline">Nome Barbiere</button>
                            </div>
                            <span className="booking-subtitle">Seleziona il tipo di trattamento</span>
                            <div className="grid_2">
                                <button className="outline">Taglio</button>
                                <button className="outline">Rasatura Barba</button>
                                <button className="outline">Taglio + Barba</button>
                                <button className="outline">American Shave</button>
                            </div>
                            {/* <Switch>
                                    <Route exact path='/checkout'
                                        render={() => <Disponibility
                                            selectDay={this.selectDay}
                                            selectTimeslot={this.selectTimeslot}
                                            day={this.state.day}
                                            timeSlotId={this.state.timeSlotId}
                                            cart={cart}
                                        />} />
                                    <Route exact path='/checkout/data'
                                        render={() => <UserData
                                            selectProfileInfo={this.selectProfileInfo}
                                            profileEmail={this.state.profileEmail}
                                            profileMobilePhone={this.state.profileMobilePhone}
                                            profileFirstName={this.state.profileFirstName}
                                            profileLastName={this.state.profileLastName}
                                            billingAddressId={this.state.billingAddressId}
                                            selectAddress={this.selectAddress}
                                            selectInvoice={this.selectInvoice}
                                            invoice={this.state.invoice}
                                            timeSlotId={this.state.timeSlotId}
                                            cart={cart}
                                            freeze={this.freeze}
                                            billingAddress={this.state.billingAddress}
                                            enableProductsReplacement={this.state.enableProductsReplacement}
                                            enableReplacement={this.enableReplacement}
                                            paymentMethod={this.state.paymentMethod}
                                            setPaymentMethod={this.setPaymentMethod}
                                        />} />
                                    <Route exact path='/checkout/confirm'
                                        render={() => <Confirm cart={cart} />}
                                    />
                                </Switch> */}
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        cart: state.cart.cart,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        freeze: (data, cartId, paymentMode) => dispatch(actionCreators.freeze(data, cartId, paymentMode)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Booking);