import React from "react";
import { useForm } from 'react-hook-form'
import Popup from "reactjs-popup";
import { Translation } from "react-i18next";
import ValidateInput from "../../../../components/UI/Input/ValidateInput";

import "./address-popup.styles.scss";

function AddressPopup(props) {

    const { register, handleSubmit, errors, watch } = useForm({
        mode: 'onBlur',
    });

    const onSubmit = data => {
        console.log(data);
        props.action(data);
        document.getElementById("close-popup").click();
    }

    return (
        <Translation>
            {t =>
                <Popup lockScroll={true} contentStyle={popupStyle} trigger={props.trigger} modal>
                    {close => (
                        <div className="address-popup">
                            <div id="close-popup" className="close" onClick={close}>
                                &times;
                            </div>
                            <div className="address-popup-header">
                                {props.title}
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off"
                                className={`address-popup-form needs-validation ${errors ? "had-validation" : ""}`}>
                                <div className="address-popup-content">
                                    <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />
                                    <div className="row popup-row">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="address"
                                                label="Indirizzo*"
                                                type="text"
                                                value={props.address && props.address.address}
                                                watch={watch("address")}
                                                errors={errors.address}
                                                register={register({
                                                    required: "true"
                                                })}
                                            />
                                        </div>
                                        <div className="col-xs-6 col-sm-2 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="streetNumber"
                                                label="Civico*"
                                                type="text"
                                                value={props.address && props.address.streetNumber}
                                                watch={watch("streetNumber")}
                                                errors={errors.streetNumber}
                                                register={register({
                                                    required: "true"
                                                })}
                                            />
                                        </div>
                                        <div className="col-xs-6 col-sm-4 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="state"
                                                label="Regione*"
                                                type="text"
                                                value={props.address && props.address.state}
                                                watch={watch("state")}
                                                errors={errors.state}
                                                register={register({
                                                    required: "true"
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row popup-row">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="city"
                                                label="Città*"
                                                type="text"
                                                value={props.address && props.address.city}
                                                watch={watch("city")}
                                                errors={errors.city}
                                                register={register({
                                                    required: "true"
                                                })}
                                            />
                                        </div>
                                        <div className="col-xs-6 col-sm-2 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="county"
                                                label="Provincia*"
                                                type="text"
                                                value={props.address && props.address.county}
                                                maxLength={2}
                                                watch={watch("county")}
                                                errors={errors.county}
                                                register={register({
                                                    required: "true",
                                                    maxLength: 2, minLength: 2
                                                })}
                                            />
                                        </div>
                                        <div className="col-xs-6 col-sm-4 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="zip"
                                                label="Cap*"
                                                type="text"
                                                value={props.address && props.address.zip}
                                                maxLength={5}
                                                watch={watch("zip")}
                                                errors={errors.zip}
                                                register={register({
                                                    required: "true",
                                                    maxLength: 5, minLength: 5
                                                })}
                                            />
                                        </div>
                                        {props.isUpdate &&
                                            <div className="d-none">
                                                <ValidateInput
                                                    name="id"
                                                    type="text"
                                                    value={props.address && props.address.id}
                                                    watch={watch("id")}
                                                    errors={errors.id}
                                                    register={register({
                                                        required: "true"
                                                    })}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="address-popup-actions">
                                    {props.isUpdate &&
                                        <button className="cta delete" onClick={() => {
                                            props.deleteAddress(props.address && props.address.id)
                                            close();
                                        }}>
                                            <span>{t("addresses.delete")}</span>
                                        </button>
                                    }
                                    <button type="submit" className="cta">
                                        <span>{props.button}</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}
                </Popup>
            }
        </Translation>
    );
}

const popupStyle = {
    borderRadius: "15px"
}

export default AddressPopup;