import React from "react";
import CookieConsent from "react-cookie-consent";
import { Translation } from "react-i18next";
import { authenticationService } from "../../../services/basis/AuthenticationService";
import config from "../../../config/config";

import "./footer.styles.scss";
export default class Footer extends React.Component {
    state = {
        companyData: ""
    }
    componentDidMount() {
        authenticationService.merchantInfo(config.DEFAULT_STORE_ID)
            .then(response => {
                this.setState({ companyData: response });
            }).catch((error) => {
                console.log(error);
            })
    }
    render() {
        const { companyData } = this.state;
        return (
            <Translation>
                {t =>
                    <div className="footer">
                        <CookieConsent
                            location="bottom"
                            buttonText="OK"
                            style={{ background: "#2B373B" }}
                            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                            expires={150}>
                            {t("footer.cookieConsent")}
                        </CookieConsent>
                        <div className="footer-content">
                            <div className="row container-fluid">
                                {/* <div className="col-lg-2 col-md-12 col-12 text-center text-lg-left mobile-col logo">
                            <img src={Logo} alt="smartbip" />
                        </div> */}
                                <div className="col-lg-3 offset-lg-1 col-md-4 col-12 text-center text-md-left mb-3 mb-lg-0 mobile-col">
                                    {/* <div className="footer-link">
                                        {t("footer.home")}
                                    </div> */}
                                    <div className="footer-link">
                                        {t("footer.termsAndConditions")}
                                    </div>
                                    <div className="footer-link">
                                        {t("footer.cookiePolicy")}
                                    </div>
                                    <div className="footer-link">
                                        {t("footer.privacyPolicy")}
                                    </div>
                                    {/* <div className="footer-link">
                                {t("footer.deliveryArea")}
                            </div>
                            <div className="footer-link">
                                {t("footer.rating")}
                            </div>
                            <div className="footer-link">
                                {t("footer.contactUs")}
                            </div> */}
                                </div>
                                {/* <div className="col-lg-3 col-md-4 col-12 text-center text-md-left mb-3 mb-lg-0 mobile-col"> */}

                                    {/* <div className="footer-link">
                                {t("footer.services")}
                            </div>
                            <div className="footer-link">
                                {t("footer.termsAndConditions")}
                            </div> */}
                                </div>
                                {/* <div className="col-lg-2 col-md-4 col-12 text-center text-md-left mobile-col d-none d-lg-block">
                            <div className="footer-text">
                                <span className="bold">
                                    {t("footer.followUs")}
                                </span>
                                <div className="mt-2">
                                    <a href=""
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="footer-social-icon"
                                    >
                                        <img src={FacebookIcon} alt="facebook" />
                                    </a>
                                    <a href=""
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="footer-social-icon"
                                    >
                                        <img src={InstagramIcon} alt="facebook" />
                                    </a>
                                </div>
                            </div>
                        </div> */}
                                {/* <div className="col-lg-3 col-md-4 col-12 text-center text-md-right">
                            <div className="footer-text">
                                <span className="bold">
                                    <nobr>
                                        {t("footer.downloadApp")}
                                    </nobr>
                                </span>
                            </div>
                            <div className="footer-apps">
                                <div>
                                    <a href=""
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <button className="cta">
                                            <img src={AppStore} alt="" />
                                            <span className="bold">
                                                {t("footer.appStore")}</span>
                                        </button>
                                    </a>
                                </div>
                                <div>
                                    <a href=""
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <button className="cta">
                                            <img src={PlayStore} alt="" />
                                            <span className="bold">
                                                {t("footer.playStore")}</span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div> */}
                            {/* </div> */}
                            {/* <div className="footer-follow d-lg-none">
                        <div className="footer-text">
                            <span className="bold">{t("footer.followUs")}</span>
                        </div>
                        <div>
                            <a href=""
                                target="_blank"
                                rel="noopener noreferrer"
                                className="footer-social-icon"
                            >
                                <img src={FacebookIcon} alt="facebook" />
                            </a>
                            <a href=""
                                target="_blank"
                                rel="noopener noreferrer"
                                className="footer-social-icon"
                            >
                                <img src={InstagramIcon} alt="facebook" />
                            </a>
                        </div>

                    </div> */}
                        </div>
                        <div className="footer-bottom row no-gutters">
                            <div className="col-12 col-sm-8 text-center text-sm-left" >
                                <span>
                                    {/* {t("footer.copyRight")} */}
                                    {companyData}
                                </span>
                            </div>
                            <div className="col-12 col-sm-4 powered text-center text-sm-right">
                                <span>{t("footer.poweredBy")} <span className="bold">RetAPPs</span></span>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}