import React from "react";
import { Link, withRouter } from "react-router-dom"
import { Translation } from 'react-i18next';
import Logo from "../../../../images/logo-smartbip.png";
import HeaderItem from "../HeaderItems/HeaderItem";
import HeaderCartItem from "../HeaderItems/HeaderCartItem";
import config from "../../../../config/config";
import MyStores from '../../../../images/MyStores.png';
import { ReactComponent as PinIcon } from '../../../../images/pin.svg';
import { ReactComponent as UserIcon } from '../../../../images/landing-user-sticky.svg';
import { ReactComponent as CartIcon } from '../../../../images/landing-cart-sticky.svg';
import { ReactComponent as CategoryIcon } from '../../../../images/category.svg';

import "./mobile-header.styles.scss";

class MobileHeader extends React.Component {
    render() {
        const { user, cart, searchInput, onChange, closeAll, showModalLogin, logoUrl } = this.props;
        return (
            <Translation>
                {t =>
                    <div className="mobile-header">
                        <div className="mobile-header-wrapper">
                            <div className="container-fluid">
                                <div className="row mobile-header-top">
                                    <div className="col-8 pin">
                                        <a href="https://my-stores.it" target="_blank" rel="noopener noreferrer">
                                            <img src={MyStores} alt="my-stores" />
                                        </a>
                                    </div>
                                    <div className="col-4 text-right">
                                        {user ?
                                            <div className="col user">
                                                <div className="user-logged-container" onClick={this.props.loginModal}>
                                                    <div className="user-logged">
                                                        {user.firstName.charAt(0)}{user.lastName.charAt(0)}
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="col user text-right">
                                                <UserIcon onClick={showModalLogin} />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="row mobile-header-bottom">
                                    <div className="col-4">
                                        <div className="logo align-items-center">
                                            <Link to="" onClick={closeAll}>
                                                {/* <img src={Logo} alt="" /> */}
                                                {/* <Logo /> */}
                                                <img src={logoUrl} alt="" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <div className="icons">
                                            <HeaderItem
                                                onClick={this.props.categoriesModal}
                                                actived={this.props.showCategories}>
                                                <CategoryIcon />
                                            </HeaderItem>
                                            {user &&
                                                <HeaderCartItem
                                                    actived={this.props.showCategories}
                                                    path="/cart"
                                                    total={this.props.renderTotal(cart)}
                                                    items={cart ? cart.itemsCount : "0"}
                                                    closeAll={this.props.closeAll}>
                                                    <CartIcon />
                                                </HeaderCartItem>
                                            }
                                            {!user &&
                                                <HeaderItem
                                                    onClick={showModalLogin}>
                                                    <CartIcon />
                                                </HeaderItem>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {(this.props.location.pathname === "/" ||
                                this.props.location.pathname === "/category" ||
                                this.props.location.pathname === "/search" ||
                                this.props.location.pathname === "/cart" ||
                                this.props.location.pathname.includes("/store") ||
                                this.props.location.pathname.includes("/product")
                            ) &&
                                <div className="mobile-header-search">
                                    <form onSubmit={e => {
                                        e.preventDefault();
                                        this.props.history.push({
                                            pathname: "/search",
                                            state: { searchInput: searchInput }
                                        })
                                        closeAll();
                                    }}>
                                        <input type="search" onChange={onChange} value={searchInput}
                                            placeholder={t("header.mobileMobilePlaceHolder")}></input>
                                        <button type="submit" className="cta">
                                            <span>{t("search")}</span>
                                        </button>
                                    </form>
                                </div>
                            }
                        </div>
                    </div>
                }
            </Translation>
        )
    }
}

export default withRouter(MobileHeader)