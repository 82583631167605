import config from "../../../config/config"
import { handleResponse } from "../../../utils/handleResponse";
import { checkResponse } from "../../../utils/checkResponse";

export default function banners() {
    return (
        fetch(`${config.baseUrl}/cli/shopbanner/readValid?category=WEB&shopId=${config.DEFAULT_STORE_ID}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json", "shopId" : `${config.DEFAULT_STORE_ID}` },
            mode: "cors",
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}