import React from "react"
import Placeholder from "../../../images/product-placeholder.png"
import { connect } from "react-redux"
import { history } from "../../../App";
import { withRouter } from "react-router-dom";
import { Translation } from "react-i18next";
import * as actionCreators from "../../../redux/store/actions/index"
import renderPrice from "../../../utils/renderPrice";

import "./card-product.styles.scss";

class CardProduct extends React.Component {
    renderPrices = (item) => {
        if (item.price !== item.netPrice) {
            return (
                <nobr>
                    <span className="text-strike strike">
                        {`${renderPrice(item.price.toFixed(2))} \u20AC`}
                    </span>
                </nobr>
            );
        } else {
            return <span>&nbsp;</span>
        }
    }

    renderPromo = (item) => {
        if (item.promotions) {
            if (item.promotions[0] !== null && item.promotions[0] !== undefined) {
                // const label = item.promotions[0].valueType === "UNKNOWN" ? "promo.unknown" : "promo";
                return (
                    <Translation>
                        {t =>
                            <div className="card-product-promo">
                                <span>{t("cardProduct.offer")}</span>
                            </div>
                        }
                    </Translation>
                );
            } else {
                return (
                    <div className="card-product-promo">
                        <span>&nbsp;</span>
                    </div>
                )
            }
        }
    }


    isAlreadyBooked = (item) => {
        var isAlreadyBooked = false;
        var itemToAdd = null;
        if (this.props.cart.items) {
            const items = this.props.cart.items;
            for (var i = 0; i < items.length; i++) {
                if (items[i].ref === item.ref) {
                    isAlreadyBooked = true;
                    itemToAdd = items[i];
                    break;
                }
            }
        } else {
            if (item.purchaseUm === "KG") {
                history.push({
                    pathname: `/product/${item.barcode}`,
                    state: { product: item }
                })
            } else {
                this.props.store(this.props.cart.id, item.barcode, item.umStep, item.um);
            }
            return;
        }

        if (isAlreadyBooked) {
            const addItem = { ...itemToAdd, available: true }
            history.push({
                pathname: `/product/${item.barcode}`,
                state: { product: addItem, isDetail: true }
            })
        } else {
            if (item.purchaseUm === "KG") {
                history.push({
                    pathname: `/product/${item.barcode}`,
                    state: { product: item }
                })
            } else {
                this.props.store(this.props.cart.id, item.barcode, item.umStep, item.um);
            }
        }
    }

    goToDetails = (item) => {
        var isAlreadyBooked = false;
        var itemToAdd = null;
        const { cart, from, category, mainCategory, categoryTitle, subCategory, activeKey } = this.props;
        if (cart && cart.items) {
            const items = cart.items;
            for (var i = 0; i < items.length; i++) {
                if (items[i].ref === item.ref) {
                    isAlreadyBooked = true;
                    itemToAdd = items[i];
                    break;
                }
            }
        } else {
            history.push({
                pathname: `/product/${item.barcode}`,
                state: { product: item, from, category, mainCategory, categoryTitle, subCategory, activeKey }
            })
            return;
        }

        if (isAlreadyBooked) {
            history.push({
                pathname: `/product/${item.barcode}`,
                state: {
                    product: itemToAdd,
                    isDetail: true,
                    from,
                    category,
                    mainCategory,
                    categoryTitle,
                    subCategory,
                    activeKey
                }
            })
        } else {
            history.push({
                pathname: `/product/${item.barcode}`,
                state: { product: item, from, category, mainCategory, categoryTitle, subCategory, activeKey }
            })
        }
    }

    render() {
        const { item } = this.props;
        return (
            <div className="card-product" style={{ ...this.props.style }}>
                <div className="card-product-image">
                    <div className="link" onClick={() => this.goToDetails(item)}>
                        <img src={item.imageUrl ? item.imageUrl : Placeholder} alt=""
                            onError={(e) => {
                                e.target.src = Placeholder
                            }}
                        />
                    </div>
                </div>
                <div className="card-product-details">
                    <div className="link" onClick={() => this.goToDetails(item)}>
                        <span>{item.description}</span>
                    </div>
                </div>
                {this.renderPromo(item)}
                <div className="card-product-prices">
                    {this.renderPrices(item)}
                    {item.promotions && item.promotions[0] !== null && item.promotions[0] !== undefined &&
                        <span className="discount">{`-2.00 \u20AC`}</span>
                    }
                    <nobr><span className="netTotal">{`${renderPrice(item.netPrice.toFixed(2))} \u20AC`}</span></nobr>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        cart: state.cart.cart,
    };
}

export default withRouter(connect(mapStateToProps)(CardProduct));